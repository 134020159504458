/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Paper from "@mui/material/Paper";
import {
  Area,
  Column,
  DisplayMode,
  FileDialog,
  FileEntity,
  Fetching,
  LoadingProps,
  Master,
  SetState,
  Table,
  TaskEntity,
  createIconColumn,
  defaultHeader,
  displayMode,
  removeArrayState,
  updateArrayState,
  useMessageEx,
  usePromise,
  webApi,
  generalComparsion,
  commentColumn,
  uploadDateColumn,
  serverErrorCodes,
  useAlertEx,
} from "lib-saitama";
import { useCallback, useMemo, useState } from "react";
import CloudDownload from "@mui/icons-material/CloudDownload";
import Delete from "@mui/icons-material/Delete";
import { handleOnClickDownload } from "../../utilities/AppUtility";

const styles = {
  tableHeight: 300,
  loading: css({
    width: "100%",
    height: 248,
  }),
};

interface Props {
  sentFileEntities: FileEntity[];

  setSentFileEntities: SetState<FileEntity[]>;

  loading: LoadingProps;

  master: Master;
}

export const SentFiles = ({ sentFileEntities, setSentFileEntities, loading, master }: Props): JSX.Element => {
  const message = useMessageEx(master.messages);

  const alert = useAlertEx(master.messages);

  const [file, setFile] = useState<{
    displayMode: DisplayMode;
    fileEntity: FileEntity;
    commit: boolean;
    taskEntity?: TaskEntity;
    onClose: (result?: FileEntity) => void;
  }>();

  const { processing, execute } = usePromise(
    useCallback(
      async (args: { fileEntity: FileEntity }) => {
        const response = await webApi.delete("files", { url: args.fileEntity.recordId });
        if (response?.code === serverErrorCodes.TheInformationHasAlreadyBeenReceivedAndCannotBeDeleted) {
          alert(76);
          return;
        }

        removeArrayState(setSentFileEntities, (value) => value.recordId === args.fileEntity.recordId);
      },
      [setSentFileEntities, alert]
    )
  );

  const handleOnClickDelete = useCallback(
    (item: FileEntity) => {
      return async () => {
        if (!(await message(29, item.name))) {
          return;
        }
        execute({ fileEntity: item });
      };
    },
    [execute, message]
  );

  const handleOnClickLink = useCallback(
    (item: FileEntity) => {
      return () => {
        setFile({
          commit: true,
          displayMode: displayMode.Edit,
          fileEntity: item,
          onClose: (result?: FileEntity) => {
            if (result != null) {
              updateArrayState(
                result,
                setSentFileEntities,
                (fileEntity: FileEntity) => fileEntity.recordId === result.recordId
              );
            }
            setFile(undefined);
          },
        });
      };
    },
    [setSentFileEntities]
  );

  const sentColumns: Array<Column<FileEntity>> = useMemo(() => {
    return [
      createIconColumn(<Delete />, handleOnClickDelete, "削除", (item) => !!item.receiptedAt),
      createIconColumn(<CloudDownload />, handleOnClickDownload, "ダウンロード", () => processing),
      {
        width: 300,
        header: "ファイル名",
        cell: "name",
      },
      commentColumn(handleOnClickLink, 350),
      uploadDateColumn(),
      {
        width: 200,
        header: "収受状態",
        bodyAlign: "center",
        cell: (item: FileEntity): string => (item.receiptedAt ? "収受済み" : ""),
        sort: (a: FileEntity, b: FileEntity, asc: boolean) =>
          generalComparsion(a.receiptedAt, b.receiptedAt) * (asc ? 1 : -1),
      },
    ];
  }, [handleOnClickDelete, processing, handleOnClickLink]);

  return (
    <>
      <Area title="過去提出ファイル一覧" remark="（収受されているファイルは削除できません。）">
        <Fetching css={styles.loading} {...loading}>
          <Paper>
            <Table
              virtual
              recordKey="recordId"
              height={styles.tableHeight}
              maxHeight={styles.tableHeight}
              items={sentFileEntities}
              columns={sentColumns}
              stripe
              options={{
                defaultHeader,
                initialSortState: { asc: false, column: sentColumns[4] },
              }}
            />
          </Paper>
        </Fetching>
        {file != null && (
          <FileDialog
            displayMode={file.displayMode}
            commit={file.commit}
            fileEntity={file.fileEntity}
            onClose={file.onClose}
            master={master}
          />
        )}
      </Area>
    </>
  );
};
