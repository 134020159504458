/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate, useParams } from "react-router";
import { Fetching, appStyles, serverErrorCodes, usePromise, webApi } from "lib-saitama";
import { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { titleBackground } from "../../utilities/AppUtility";

const styles = {
  subTitleBox: css({
    background: titleBackground,
  }),
  loading: css({
    width: "100%",
    height: 500,
  }),
};

export const AccountComplete = (): JSX.Element => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [message, setMessage] = useState<{ title: string; message: JSX.Element }>();

  const register = usePromise(
    useCallback(async ({ id }: { id?: string }) => {
      const response = await webApi.post("companies/complete", {}, { url: id });
      if (response == null) {
        setMessage({
          title: "アカウント登録完了",
          message: (
            <Typography>
              アカウント登録が完了いたしました。ログイン画面へをクリックし、ログインを行ってください。
            </Typography>
          ),
        });
      } else if (response.code === serverErrorCodes.ExpiredUrl) {
        setMessage({
          title: "アカウント登録エラー",
          message: (
            <>
              <Typography>認証URLの有効期限が切れているか、無効なため、アカウント登録が完了しませんでした。</Typography>
              <Typography>再度アカウント登録を行ってください。</Typography>
            </>
          ),
        });
      } else if (response.code === serverErrorCodes.CreatedAccount) {
        setMessage({
          title: "アカウント登録エラー",
          message: (
            <>
              <Typography>ご指定のユーザーアカウントは登録済みです。</Typography>
              <Typography>ログイン画面からログインを行ってください。</Typography>
            </>
          ),
        });
      }
    }, []),
    { id }
  );

  const onError = (retake: () => void): JSX.Element => {
    return (
      <div onClick={retake}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" rowSpacing={1}>
          <Grid item xs={12}>
            <Box css={[appStyles.subTitleBox, styles.subTitleBox]}>
              <Typography css={appStyles.subTitle}>アカウント登録エラー</Typography>
            </Box>
          </Grid>
          <Grid container direction="column" item xs={12} justifyContent="center" alignItems="center">
            <Grid item css={css({ marginTop: 200 })}>
              <Typography>通信処理に失敗しました。</Typography>
              <Typography>ここを押下し再読み込みを行っていただくか、メールのurlを再度押下してください。</Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Fetching css={styles.loading} {...register.loading} onError={onError}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" rowSpacing={1}>
        <Grid item xs={12}>
          <Box css={[appStyles.subTitleBox, styles.subTitleBox]}>
            <Typography css={appStyles.subTitle}>{message?.title}</Typography>
          </Box>
        </Grid>
        <Grid container direction="column" item xs={12} justifyContent="center" alignItems="center">
          <Grid item css={css({ marginTop: 200 })}>
            {message?.message}
          </Grid>
          <Grid item css={css({ marginTop: 150 })}>
            <Button variant="contained" onClick={() => navigate("login")}>
              ログイン画面へ
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fetching>
  );
};
