/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  appStyles,
  Design,
  TextField,
  usePromise,
  useInputManager,
  webApi,
  useAlertEx,
  genericStyles,
  validateInputs,
  systemParameterId,
  serverErrorCodes,
} from "lib-saitama";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AppActions, AppProvider } from "../../providers/AppProvider";
import { gBizLogin, titleBackground } from "../../utilities/AppUtility";

const styles = {
  text: css({
    width: 300,
  }),
  appBar: css({
    background: titleBackground,
  }),
  title: css({
    textShadow: "3px 3px 4px rgba(107,73,61,1)",
  }),
  gbiz: css({
    width: 200,
    color: "white",
    background: "rgb(0,152,208)",
    "&:hover": {
      background: "rgb(0, 132, 188)",
    },
  }),
  marginTop: css({
    marginTop: Design.margin,
  }),
};

interface Input {
  id: string;

  password: string;
}

export const Login = (): JSX.Element => {
  const navigate = useNavigate();

  const master = AppProvider.useGlobalState("master");

  const alert = useAlertEx(master.messages);

  const dispatch = AppProvider.useDispatch();

  const [input] = useInputManager<Input>({ id: "", password: "" });

  const login = usePromise(
    useCallback(
      async (args: { id: string; password: string }): Promise<void> => {
        const response = await webApi.post("securities/login", { email: args.id, password: args.password });

        if (response?.code === serverErrorCodes.TheUserIdOrPasswordIsDifferent) {
          alert(72);
        } else if (response?.code === serverErrorCodes.LockedAccount) {
          alert(77);
        } else {
          dispatch({ type: AppActions.SET_ACCOUNT, value: response });
          navigate("/main/case");
        }
      },
      [dispatch, navigate, alert]
    )
  );

  const handleOnClick = (): void => {
    if (
      !validateInputs(
        alert,
        { value: input.data.id, message: "ユーザーID" },
        { value: input.data.password, message: "パスワード" }
      )
    ) {
      return;
    }

    login.execute({ id: input.data.id, password: input.data.password });
  };

  const handleOnClickGBizLogin = async (): Promise<void> => {
    const host = master.systemParameter(systemParameterId.GBizHost) ?? "";
    const redirectUrl = process.env.REACT_APP_REDIRECT_URI_LOGIN ?? "";
    gBizLogin(host, redirectUrl);
  };

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} sx={{ height: 64 }}>
          <Box>
            <AppBar css={styles.appBar}>
              <Toolbar>
                <Typography css={[appStyles.title, styles.title]}>埼玉県財務受付ポータル</Typography>
              </Toolbar>
            </AppBar>
          </Box>
        </Grid>
        <Grid item xs={12} container justifyContent="center" alignItems="center" css={css({ overflow: "hidden" })}>
          <Grid item sx={{ margin: "8px 0px 8px 0px" }}>
            <img src={`${process.env.PUBLIC_URL}/logo.jpg`} />
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <Typography>
            埼玉県財務受付ポータルは、埼玉県庁と納品書や請求書などの送受信・連絡を行うサイトです。
          </Typography>
        </Grid>
        <Grid xs={3}></Grid>
        <Grid
          xs={6}
          css={[genericStyles.width100percent, css({ minHeight: 360 })]}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <TextField
              css={[styles.text]}
              label="ユーザーID"
              value={input.data.id}
              onChange={input.handleOnChange("id")}
              labelPosition="static"
            />
          </Grid>
          <Grid item css={styles.marginTop}>
            <TextField
              css={[styles.text]}
              label="パスワード"
              type="password"
              value={input.data.password}
              onChange={input.handleOnChange("password")}
              labelPosition="static"
            />
          </Grid>
          <Grid item css={styles.marginTop}>
            <Button css={css({ width: 200 })} variant="contained" onClick={handleOnClick}>
              ログイン
            </Button>
          </Grid>
          <Grid
            item
            css={css({ width: 450, marginTop: Design.margin })}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Typography variant="body2">
                初めて利用される方は
                <Link css={css({ cursor: "pointer" })} href={undefined} onClick={() => navigate("/account")}>
                  こちら
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} css={styles.marginTop}>
              <Typography variant="body2">
                パスワードをお忘れの方もしくはパスワードを変更する方は
                <Link css={css({ cursor: "pointer" })} href={undefined} onClick={() => navigate("/password/reissue")}>
                  こちら
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item css={css({ marginTop: Design.margin * 3 })}>
            <Button css={styles.gbiz} variant="contained" onClick={handleOnClickGBizLogin} disabled={login.processing}>
              GビズIDでログインする
            </Button>
          </Grid>
        </Grid>
        <Grid xs={3}></Grid>
        <Grid xs={12} sx={{ borderTop: "dotted", borderWidth: 1 }}></Grid>
        <Grid xs={3}></Grid>
        <Grid xs={6} container direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12} css={css({ marginTop: Design.margin * 4 })}>
            <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
              {master.systemParameter(systemParameterId.ContactHelpDesk)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
