/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { appStyles } from "lib-saitama";
import { titleBackground } from "../../utilities/AppUtility";

const styles = {
  subTitleBox: css({
    background: titleBackground,
  }),
};

const halfWidthSymbols = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~｢｣､-`｡ﾞ･ﾟ";

export const Terms = () => {
  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" rowSpacing={4}>
        <Grid item xs={12}>
          <Box css={[appStyles.subTitleBox, styles.subTitleBox]}>
            <Typography css={appStyles.subTitle}>利用規約</Typography>
          </Box>
        </Grid>
        <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
          <Typography>埼玉県財務受付ポータル利用規約</Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>１</span>目的
          </Typography>
          <Typography css={css({ marginLeft: 16, textIndent: "1rem" })}>
            この規約は、埼玉県財務受付ポータル（以下「本システム」といいます。）を利用して埼玉県（以下「本県」といいます。）に対し、インターネットを通じて申請・届出等の手続を行うために必要な事項を定めるものです。
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>２</span>利用規約の同意
          </Typography>
          <Typography css={css({ marginLeft: 16, textIndent: "1rem" })}>
            本システムを利用して申請・届出等手続を行うためには、この規約に同意することが必要です。このことを前提に、本県は本システムのサービスを提供します。本システムを利用した方は、この規約に同意したものとみなします。何らかの理由によりこの規約に同意することができない場合は、本システムを利用することができません。なお、閲覧のみについても、この規約に同意したものとみなします。
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>３</span>ユーザーＩＤ・パスワード等の登録・変更及び削除
          </Typography>
          <Typography css={css({ marginLeft: 16 })}>
            <Typography css={css({ textIndent: "1rem" })}>
              本システムを利用して手続を行う場合は、利用者たる本人が利用方法に従い事業者登録を行うことができるものとします。
            </Typography>
            <Typography>
              （１）事業者登録を行う際は、ユーザーＩＤ、パスワード、事業者名（氏名）、住所、その他の必要な事項を本システム上で登録してください。
            </Typography>
            <Typography>
              （２）事業者名（氏名）、住所、代表者等に変更があった場合は変更手続を行ってください。
            </Typography>
            <Typography>
              （３）本システムは、利用者が登録したメールアドレスへＵＲＬを送信します。利用者は、メールに記載されているＵＲＬにアクセスすることで、本登録を行います。
            </Typography>
            <Typography>（４）事業者登録にて登録された情報は、本県にて管理されます。</Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>４</span>ユーザーＩＤ・パスワード等の管理
          </Typography>
          <Typography css={css({ marginLeft: 16 })}>
            <Typography css={css({ textIndent: "1rem" })}>利用者は、次の事項をご確認ください。</Typography>
            <Typography>（１）ユーザーＩＤ、パスワードは、他者に知られないように管理してください。</Typography>
            <Typography>（２）他者からのパスワード等の照会には応じないでください。</Typography>
            <Typography>（３）安全性をより高めるため、パスワードは、定期的に変更してください。</Typography>
            <Typography>
              （４）ユーザーＩＤ、パスワードは、再発行しません。なお、ユーザーＩＤ、パスワードを紛失し、盗難に遭い、又は不正使用されたことが分かったときは、速やかに問い合わせ先に連絡し、その指示に従ってください。
            </Typography>
            <Typography>
              （５）ユーザーＩＤ及びパスワードについては、特に有効期限は設けないものとしますが、ユーザーＩＤ及びパスワードの利用が７年間行われない場合は、本県の判断で抹消することができるものとします。
            </Typography>
            <Typography>
              （６）本県は、ユーザーＩＤ及びパスワードを使用して行われた手続きについては、本人がこれを行ったものとみなします。
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>５</span>利用者の責務
          </Typography>
          <Typography css={css({ marginLeft: 16, textIndent: "1rem" })}>
            利用者は、本システムが障害その他の理由により利用できなくなった場合には、他の方法による手続を行うこととし、このことを承知した上で本システムを利用するものとします。
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>６</span>利用時間
          </Typography>
          <Typography css={css({ marginLeft: 16, textIndent: "1rem" })}>
            本システムは、原則として２４時間利用することができます。ただし、定期点検や緊急の保守･点検を行う場合は、本システムの一部又は全部を停止することがあります。本システムの運用停止を行う場合は、本システムのポータルサイトで事前にお知らせしますが、障害等で緊急を要する場合は、予告なしで停止することがあります。
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>７</span>利用可能な文字
          </Typography>
          <Typography css={css({ marginLeft: 16 })}>
            <Typography css={css({ textIndent: "1rem" })}>
              本システムにおいて使用可能な文字は、次の各号に掲げる場合に応じ、当該各号に定める文字に限り、その他の外字又は機種依存文字等の使用はできないものとします。
            </Typography>
            <Typography>（１）半角カタカナ、半角英数字、半角記号（注１）</Typography>
            <Typography>（２）全角ひらがな、全角カタカナ、全角英数字、全角記号（注２）</Typography>
            <Typography>（３）JIS第一水準漢字及びJIS第二水準漢字</Typography>
            <Typography>（４）補助漢字</Typography>
            <Typography>（５）JIS第三水準漢字及びJIS第四水準漢字</Typography>
            <Typography>（ただし、サロゲートペア、CJK統合漢字拡張文字Aを除く。）</Typography>
            <br />
            <Typography>（注１）半角空白{halfWidthSymbols}</Typography>
            <Typography>
              （注２）全角空白、。，．・：；？！゛゜´｀¨＾￣＿ヽヾゝゞ〃仝々〆〇ー―‐／＼～∥｜…‥‘’“”（）〔〕［］｛｝〈〉《》「」『』【】＋－±×÷＝≠＜＞≦≧∞∴♂♀°′″℃￥＄￠￡％＃＆＊＠§☆★○●◎◇◆□■△▲▽▼※〒→←↑↓〓ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩαβγδεζηθικλμνξοπρστυφχψω
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>８</span>禁止事項
          </Typography>
          <Typography css={css({ marginLeft: 16 })}>
            <Typography css={css({ textIndent: "1rem" })}>
              本システムの利用に当たっては、次に掲げる行為を禁止します。
            </Typography>
            <Typography>（１）本システムを本県への申請・届出等手続以外の目的で利用すること。</Typography>
            <Typography>（２）本システムに対し、不正にアクセスすること。</Typography>
            <Typography>（３）本システムの管理及び運営を故意に妨害、破壊すること。</Typography>
            <Typography>（４）本システムに対し、ウィルスに感染したファイルを故意に送信すること。</Typography>
            <Typography>（５）他の利用者のＩＤ又はパスワード等を不正に使用すること。</Typography>
            <Typography>（６）自己のＩＤ、パスワード等を他人に譲渡すること。</Typography>
            <Typography>（７）他者のプライバシーを侵害する行為をすること。</Typography>
            <Typography>（８）その他法令等に違反すると認められる行為をすること。</Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>９</span>禁止行為に対する防御措置
          </Typography>
          <Typography css={css({ marginLeft: 16, textIndent: "1rem" })}>
            本県は、前項各号のいずれかに該当する行為が明らかな場合又は該当する行為があると本県が判断した場合は、当該行為を行った利用者の登録若しくは利用者から収集した情報を抹消し、又は本システムを停止する等必要な措置を行うことができるものとします。
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>10</span>免責事項
          </Typography>
          <Typography css={css({ marginLeft: 16 })}>
            <Typography>
              （１）本県は、利用者が本システムを利用したことにより発生した利用者の損害及び利用者が第三者に与えた損害に対して一切の責任を負いません。
            </Typography>
            <Typography>
              （２）本県は、その裁量において、本システムの改修、運用停止又は中断等を利用者への予告なく行うことができることとします。また、これにより生じたいかなる損害に対して、一切の責任を負いません。
            </Typography>
            <Typography>
              （３）本県は、利用者が使用するパソコンの障害、不具合、通信回線上の障害その他本県の責めに帰さない理由による本システムの障害等により発生した利用者の損害及び利用者が第三者に与えた損害に対して、一切の責任を負いません。
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>11</span>著作権
          </Typography>
          <Typography css={css({ marginLeft: 16, textIndent: "1rem" })}>
            本システムに含まれているプログラム及びその他著作物に関する著作権は、国際著作権条約及び日本国の著作権関連法令によって保護されています。本システムに含まれているプログラム及びその他著作物の修正、複製、改ざん又は販売等の行為を禁じます。
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>12</span>個人情報の保護
          </Typography>
          <Typography css={css({ marginLeft: 16 })}>
            <Typography>
              （１）本県は、「埼玉県個人情報保護条例」及び「埼玉県情報セキュリティポリシー」に従って個人情報の保護を行います。
            </Typography>
            <Typography>
              （２）収集した個人情報は、下記の目的で利用し、法令・条例上の要請等によるものを除き、目的外の利用及び第三者への提供を行いません。
            </Typography>
            <Typography css={css({ textIndent: "1rem" })}>・申請者情報管理業務</Typography>
            <Typography css={css({ textIndent: "1rem" })}>・電子申請の受付・審査業務</Typography>
            <Typography css={css({ textIndent: "1rem" })}>・その他、本システムの運用に必要な業務</Typography>
            <Typography>
              （３）本システムを利用して利用者から本県へ、又は本県から利用者へ送信する個人情報は、データの暗号化によって保護されます。
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>13</span>準拠法及び管轄
          </Typography>
          <Typography css={css({ marginLeft: 16, textIndent: "1rem" })}>
            この利用規約は日本の国内法に準拠するものとします。また、本システムの利用又はこの規約に関して本県と利用者の間に生ずるすべての紛争については、さいたま地方裁判所を第一審の専属的合意管轄裁判所とします。
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <span css={css({ marginRight: "1rem" })}>14</span>利用規約の変更
          </Typography>
          <Typography css={css({ marginLeft: 16, textIndent: "1rem" })}>
            本県は、必要があると認めるときは、予告なくこの規約を変更できるものとします。この規約の変更後に利用者が本システムを利用したときは、利用者は、変更後の規約に同意したものとみなします。
          </Typography>
        </Grid>
        <Grid item>
          <Typography>附則</Typography>
          <Typography css={css({ marginLeft: 16 })}>この規約は、令和４年１月３１日から施行します。</Typography>
        </Grid>
      </Grid>
    </>
  );
};
