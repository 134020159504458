/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AppActions, AppProvider } from "../providers/AppProvider";
import { CookiePropertyName } from "../utilities/AppUtility";
import { Design, appStyles, deleteCookie, genericStyles, usePromise, webApi } from "lib-saitama";
import { useNavigate } from "react-router-dom";
import Settings from "@mui/icons-material/Settings";

const styles = {
  main: css({
    flexGrow: 1,
    padding: Design.margin * 3,
    transition: "margin-left .25s ease-out",
    marginLeft: 0,
    overflowX: "hidden",
    marginTop: 64,
  }),
  appBar: (background: string) =>
    css({
      background,
      transition: "margin-left .25s ease-out, width .5s ease-out",
    }),
  account: css({
    marginRight: Design.margin * 3,
    color: "black",
  }),
  title: css({
    textShadow: "3px 3px 4px rgba(107,73,61,1)",
  }),
};

interface Props {
  children: JSX.Element;
  title: string;
  background: string;
  companyName: string;
  representativeName: string;
  defaultOpen: boolean;
}

export const PrivateAppBar = React.memo((props: Props): JSX.Element => {
  const dispatch = AppProvider.useDispatch();

  const navigate = useNavigate();

  const logout = usePromise(
    useCallback(async () => {
      await webApi.post("securities/logout");
      deleteCookie(Object.values(CookiePropertyName));
      webApi.setJwt("");
      dispatch({ type: AppActions.SET_ACCOUNT, value: undefined });
    }, [dispatch])
  );

  const handleOnClickLogout = (): void => {
    logout.execute();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar css={styles.appBar(props.background)} position="fixed">
        <Toolbar>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Typography css={[appStyles.title, styles.title]}>{props.title}</Typography>
              </Grid>
            </Box>
            <Box>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Typography css={styles.account}>{props.companyName}：</Typography>
                <Typography css={styles.account}>{props.representativeName}</Typography>
                <Tooltip title="アカウント設定">
                  <IconButton css={genericStyles.marginRight} onClick={() => navigate("company")}>
                    <Settings color="primary" />
                  </IconButton>
                </Tooltip>
                <Button variant="outlined" onClick={handleOnClickLogout}>
                  ログアウト
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box css={styles.main}>{props.children}</Box>
    </Box>
  );
});
