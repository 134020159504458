/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Paper from "@mui/material/Paper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import {
  Area,
  Column,
  DisplayMode,
  LikeLink,
  Fetching,
  LoadingProps,
  Master,
  NotificationDialog,
  NotificationEntity,
  Table,
  createIconColumn,
  defaultHeader,
  displayMode,
  generalComparsion,
  generateUuid,
  genericStyles,
  provider,
  CellGrid,
  providerColumn,
} from "lib-saitama";

const styles = {
  tableHeight: 280,
  loading: css({
    width: "100%",
    height: 280,
  }),
};

interface Props {
  recordId: string;

  master: Master;

  notificationEntities: NotificationEntity[];

  loading: LoadingProps;
}

export const Notification = (props: Props): JSX.Element => {
  const [notificationEntities, setNotificationEntities] = useState<NotificationEntity[]>(props.notificationEntities);

  useEffect(() => setNotificationEntities(props.notificationEntities), [props.notificationEntities]);

  const [notificationEntity, setNotificationEntity] = useState<{
    displayMode: DisplayMode;
    entity: NotificationEntity;
  }>();

  const handleOnClickLink = useCallback((item: NotificationEntity) => {
    return () => {
      setNotificationEntity({
        displayMode: displayMode.Reference,
        entity: item,
      });
    };
  }, []);

  const columns: Array<Column<NotificationEntity>> = useMemo(() => {
    return [
      createIconColumn(),
      {
        header: "タイトル",
        cell: (item: NotificationEntity): JSX.Element => (
          <CellGrid>
            <LikeLink css={genericStyles.marginLeft} onClick={handleOnClickLink(item)} text={item.title} />
          </CellGrid>
        ),
        sort: (a: NotificationEntity, b: NotificationEntity, asc: boolean) =>
          generalComparsion(a.title, b.title) * (asc ? 1 : -1),
      },
      providerColumn(props.master),
      {
        width: 150,
        fix: true,
        header: "通知日",
        bodyAlign: "center",
        cell: (item: NotificationEntity) => format(item.notificatedAt, "yyyy/MM/dd HH:mm"),
        sort: (a: NotificationEntity, b: NotificationEntity, asc: boolean) =>
          generalComparsion(a.notificatedAt, b.notificatedAt) * (asc ? 1 : -1),
      },
    ];
  }, [props.master, handleOnClickLink]);

  const handleOnClickAdd = useCallback(() => {
    setNotificationEntity({
      displayMode: displayMode.Register,
      entity: {
        recordId: generateUuid(),
        caseRecordId: props.recordId,
        title: "",
        provider: provider.Company,
        notificatedAt: new Date(),
        deleted: false,
        createdAt: new Date(),
        createdBy: "",
        updatedAt: new Date(),
        updatedBy: "",
      },
    });
  }, [props.recordId]);

  const handleOnClose = (result?: NotificationEntity) => {
    if (result != null) {
      setNotificationEntities((value) => [result, ...value]);
    }
    setNotificationEntity(undefined);
  };

  return (
    <>
      <Area title="お知らせ一覧">
        <Fetching css={css({ width: "100%", height: styles.tableHeight })} {...props.loading}>
          <Paper>
            <Table
              virtual
              recordKey="recordId"
              height={styles.tableHeight}
              maxHeight={styles.tableHeight}
              items={notificationEntities}
              columns={columns}
              onClickAdd={handleOnClickAdd}
              stripe
              options={{ defaultHeader, initialSortState: { asc: false, column: columns[3] } }}
            />
          </Paper>
        </Fetching>
        {notificationEntity != null && (
          <NotificationDialog
            notificationEntity={notificationEntity.entity}
            messages={props.master.messages}
            displayMode={notificationEntity.displayMode}
            onClose={handleOnClose}
          />
        )}
      </Area>
    </>
  );
};
