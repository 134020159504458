import { connect, IAction, Master } from "lib-saitama";
import { ExternalAccount } from "../models/ExternalAccount";

const initialState: AppType = {
  account: undefined,
  master: new Master([], [], [], [], [], [], [], []),
  transition: undefined,
};

interface AppType {
  account?: ExternalAccount;
  master: Master;
  transition?: unknown;
}

export const AppActions = {
  SET_ACCOUNT: "SET_ACCOUNT",
  SET_MASTERS: "SET_MASTERS",
  SET_TRANSITION: "SET_TRANSITION",
} as const;

function reducer(state: AppType, action: IAction): AppType {
  switch (action.type) {
    case AppActions.SET_ACCOUNT:
      return { ...state, account: action.value };
    case AppActions.SET_MASTERS:
      return { ...state, master: action.value };
    case AppActions.SET_TRANSITION:
      return { ...state, transition: action.value };
    default:
      return state;
  }
}

export const AppProvider = connect<AppType>(reducer, initialState);
