/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { appStyles, Design } from "lib-saitama";

const styles = {
  main: css({
    flexGrow: 1,
    padding: Design.margin * 3,
    transition: "margin-left .25s ease-out",
    marginLeft: 0,
    overflowX: "hidden",
    marginTop: 64,
  }),
  appBar: (background: string) =>
    css({
      background,
      transition: "margin-left .25s ease-out",
    }),
  title: css({
    textShadow: "3px 3px 4px rgba(107,73,61,1)",
  }),
};

interface Props {
  children: JSX.Element;
  title: string;
  background: string;
}

export const PublicAppBar = React.memo((props: Props): JSX.Element => {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar css={styles.appBar(props.background)} position="fixed">
        <Toolbar>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Typography css={[appStyles.title, styles.title]}>{props.title}</Typography>
              </Grid>
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box css={styles.main}>{props.children}</Box>
    </Box>
  );
});
