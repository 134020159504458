/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  CaseEntity,
  CaseSummaryModel,
  FileEntity,
  NotificationEntity,
  appStyles,
  usePromise,
  webApi,
} from "lib-saitama";
import { titleBackground } from "../../utilities/AppUtility";
import { useCallback, useRef, useState } from "react";
import { BaseInfomation } from "./BaseInfomation";
import { AppProvider } from "../../providers/AppProvider";
import { Notification } from "./Notification";
import { CaseModel, TaskEntityESignEx } from "../../models/CaseModel";
import { ESign } from "./ESign";
import { FromSaitama } from "./FromSaitama";
import { Submission } from "./Submission";
import { SentFiles } from "./SentFiles";

const styles = {
  subTitleBox: css({
    background: titleBackground,
  }),
  tableHeight: 300,
};

interface Props {
  caseSummaryModel: CaseSummaryModel;

  onClose: (result?: CaseSummaryModel) => void;
}

export const Case = (props: Props): JSX.Element => {
  const master = AppProvider.useGlobalState("master");

  const account = AppProvider.useGlobalState("account");

  const caseSummaryModel = useRef<CaseSummaryModel>(props.caseSummaryModel);

  const [caseEntity, setCaseEntity] = useState<CaseEntity>({
    recordId: "",
    periodFrom: 2000,
    periodTo: 2000,
    id: "",
    name: "",
    organizationCode: "",
    formalOrgName: "",
    fullName: "",
    userId: "",
    nameKanji: "",
    receptionUpdatedAt: new Date(),
    deleted: false,
    createdAt: new Date(),
    createdBy: "",
    updatedAt: new Date(),
    updatedBy: "",
  });

  const [notificationEntities, setNotificationEntities] = useState<NotificationEntity[]>([]);

  const [eSignTaskEntities, setESignTaskEntities] = useState<TaskEntityESignEx[]>([]);

  const [fromSaitamaFileEntities, setFromSaitamaFileEntities] = useState<FileEntity[]>([]);

  const [sentFileEntities, setSentFileEntities] = useState<FileEntity[]>([]);

  const promise = usePromise(
    useCallback(async ({ recordId }: { recordId: string }) => {
      const caseModel: CaseModel = await webApi.fetch("cases", { url: recordId });

      setCaseEntity(caseModel.caseEntity);

      setNotificationEntities(caseModel.notificationEntities);

      setESignTaskEntities(caseModel.eSignTaskEntities);

      setFromSaitamaFileEntities(caseModel.fromSaitamaFileEntities);

      setSentFileEntities(caseModel.sentFileEntities);
    }, []),
    { recordId: props.caseSummaryModel.recordId }
  );

  const handleOnClickBack = () => {
    props.onClose(caseSummaryModel.current);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowSpacing={2}
        columnSpacing={1}
      >
        <Grid item xs={12}>
          <Box css={[appStyles.subTitleBox, styles.subTitleBox]}>
            <Typography css={appStyles.subTitle}>調達案件詳細</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} container direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={1}>
          <Grid item>
            <Button css={appStyles.middleButton} variant="contained" onClick={handleOnClickBack}>
              案件一覧
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <BaseInfomation
            master={master}
            account={account}
            caseEntity={caseEntity}
            caseSummaryModel={caseSummaryModel}
            loading={promise.loading}
          />
        </Grid>
        <Grid item xs={6}>
          <Notification
            recordId={props.caseSummaryModel.recordId}
            master={master}
            notificationEntities={notificationEntities}
            loading={promise.loading}
          />
        </Grid>
        <Grid item xs={12}>
          <ESign
            recordId={props.caseSummaryModel.recordId}
            taskEntities={eSignTaskEntities}
            loading={promise.loading}
            master={master}
          />
        </Grid>
        <Grid item xs={12}>
          <Submission
            recordId={props.caseSummaryModel.recordId}
            setSentFileEntities={setSentFileEntities}
            master={master}
          />
        </Grid>
        <Grid item xs={12}>
          <FromSaitama fileEntities={fromSaitamaFileEntities} loading={promise.loading} master={master} />
        </Grid>
        <Grid item xs={12} container>
          <SentFiles
            sentFileEntities={sentFileEntities}
            setSentFileEntities={setSentFileEntities}
            loading={promise.loading}
            master={master}
          />
        </Grid>
      </Grid>
    </>
  );
};
