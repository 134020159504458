/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TaskEntityESignEx } from "../../models/CaseModel";
import {
  Area,
  Column,
  FileDownloadDialog,
  FileEntity,
  Fetching,
  LoadingProps,
  Master,
  Table,
  appStyles,
  genericStyles,
  tableDesign,
} from "lib-saitama";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const styles = {
  tableHeight: 200,
  loading: css({
    width: "100%",
    height: 200,
  }),
  row: {
    height: tableDesign.tableRowHeight,
  },
};

interface Props {
  recordId: string;

  taskEntities: TaskEntityESignEx[];

  loading: LoadingProps;

  master: Master;
}

export const ESign = (props: Props): JSX.Element => {
  const [taskEntities, setTaskEntities] = useState<TaskEntityESignEx[]>([]);

  const [fileEntities, setFileEntities] = useState<FileEntity[]>();

  useEffect(() => {
    setTaskEntities(props.taskEntities);
  }, [props.taskEntities]);

  const handleOnClickFile = useCallback((item: TaskEntityESignEx) => {
    return () => setFileEntities(item.fileEntities);
  }, []);

  const columns: Array<Column<TaskEntityESignEx>> = useMemo(() => {
    return [
      {
        header: "タスク名",
        cell: "name",
        sort: false,
      },
      {
        header: "契約書名",
        cell: "title",
        sort: false,
      },
      {
        width: 250,
        fix: true,
        header: "契約状態",
        bodyAlign: "center",
        cell: (item: TaskEntityESignEx): string => {
          if (item.documentId == null) {
            return "";
          }
          return item.status === props.master.getTaskMaster(item.systemMasterId, item.codeId)?.completedValue
            ? "完了"
            : "未完了";
        },
        sort: false,
      },
      {
        width: 250,
        fix: true,
        header: "合意済契約書",
        bodyAlign: "center",
        cell: (item: TaskEntityESignEx) => {
          if (
            item.documentId != null &&
            item.status === props.master.getTaskMaster(item.systemMasterId, item.codeId)?.completedValue
          ) {
            return (
              <Grid
                css={[genericStyles.w100p, styles.row]}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button css={appStyles.largeButton} variant="contained" onClick={handleOnClickFile(item)}>
                  ファイル一覧
                </Button>
              </Grid>
            );
          } else {
            return <></>;
          }
        },
        sort: false,
      },
    ];
  }, [handleOnClickFile, props.master]);

  return (
    <>
      <Area title="電子契約状況一覧">
        <Fetching css={styles.loading} {...props.loading}>
          <Paper>
            <Table
              virtual
              recordKey="recordId"
              height={styles.tableHeight}
              maxHeight={styles.tableHeight}
              items={taskEntities}
              columns={columns}
              stripe
            />
          </Paper>
        </Fetching>
        {fileEntities != null && (
          <FileDownloadDialog fileEntities={fileEntities} onClose={() => setFileEntities(undefined)} />
        )}
      </Area>
    </>
  );
};
