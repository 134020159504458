/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import {
  corporateCategory,
  emailFormatRegex,
  emailStringRegex,
  gBizLogin,
  halfWidthSymbols,
  maxPasswordLength,
  minPasswordLength,
  numberAndHyphenRegex,
  onlyNumberRegex,
  passwordRegex,
  titleBackground,
} from "../../utilities/AppUtility";
import { useCallback, useEffect, useMemo, useState } from "react";
import Delete from "@mui/icons-material/Delete";
import { StaffDialog } from "./StaffDialog";
import { AppActions, AppProvider } from "../../providers/AppProvider";
import {
  CellGrid,
  Column,
  ComboItem,
  CompanyEntity,
  Design,
  DisplayMode,
  LikeLink,
  PostalCodeEntity,
  Select,
  StaffEntity,
  Table,
  TextField,
  appStyles,
  codeMasterGroupId,
  createIconColumn,
  defaultHeader,
  displayMode,
  generalComparsion,
  generateUuid,
  genericStyles,
  isNullOrEmpty,
  mergeArrayState,
  removeArrayState,
  serverErrorCodes,
  systemParameterId,
  useAlertEx,
  useInputManager,
  useMessageEx,
  usePromise,
  validateInputs,
  webApi,
} from "lib-saitama";
import { useNavigate } from "react-router";
import { CompanyModel } from "../../models/CompanyModel";
import { ExternalAccount } from "../../models/ExternalAccount";

const styles = {
  subTitleBox: css({
    background: titleBackground,
  }),
  tableHeight: 300,
};

const corporateIdLength = 13;

const postalCodeLength = 7;

interface Props {
  displayMode: DisplayMode;
}

export const Account = (props: Props): JSX.Element => {
  const master = AppProvider.useGlobalState("master");

  const account = AppProvider.useGlobalState("account");

  const dispatch = AppProvider.useDispatch();

  const navigate = useNavigate();

  const alert = useAlertEx(master.messages);

  const message = useMessageEx(master.messages);

  const editMode = useMemo(() => props.displayMode === displayMode.Edit, [props.displayMode]);

  const [fixAccountId, setFixAccountId] = useState<boolean>(false);

  const [input, setInput] = useInputManager<CompanyEntity & { passwordConfirm?: string }>(
    account?.companyEntity ?? {
      recordId: generateUuid(),
      companyId: "",
      accountId: "",
      password: "",
      corporateCategory: corporateCategory.unselected,
      name: "",
      corporateId: "",
      representativeName: "",
      representativeKana: "",
      representativePost: "",
      postalCode: "",
      prefecture: "",
      city: "",
      approved: false,
      createdBy: "",
      createdAt: new Date(),
      updatedBy: "",
      updatedAt: new Date(),
      deleted: false,
    }
  );

  const [staffs, setStaffs] = useState<StaffEntity[]>(account?.staffEntities == null ? [] : [...account.staffEntities]);

  const [staff, setStaff] = useState<{ staff: StaffEntity; displayMode: DisplayMode }>();

  const [checked, setChecked] = useState(editMode);

  const transition = AppProvider.useGlobalState("transition");

  useEffect(() => {
    if (transition == null) {
      return;
    }
    setFixAccountId(true);
    setInput((value) => {
      const entity = transition as CompanyEntity;
      const companyEntity = {
        ...value,
        corporateCategory: entity.corporateCategory,
        corporateId: entity.corporateId,
        representativeName: entity.representativeName,
        representativeKana: entity.representativeKana,
        prefecture: entity.prefecture,
        city: entity.city,
        street: entity.street,
        applicantName: entity.applicantName,
        applicantKana: entity.applicantKana,
        applicantDepartment: entity.applicantDepartment,
        applicantTel: entity.applicantTel,
      };
      if (!editMode) {
        companyEntity.accountId = entity.accountId;
        companyEntity.name = entity.name;
      }

      return companyEntity;
    });
    dispatch({ type: AppActions.SET_TRANSITION, value: undefined });
  }, [dispatch, editMode, setInput, transition]);

  const handleOnClickAddStaff = () => {
    setStaff({
      staff: {
        recordId: generateUuid(),
        companyRecordId: input.data.recordId,
        orderIndex: 0,
        name: "",
        email: "",
      },
      displayMode: displayMode.Register,
    });
  };

  const handleOnClose = (staff?: StaffEntity) => {
    if (staff != null) {
      mergeArrayState(staff, setStaffs, (value) => staff.recordId === value.recordId);
    }
    setStaff(undefined);
  };

  const handleOnClickAddress = () => {
    const postalCode = input.data.postalCode;
    if (!validateInputs(alert, { value: postalCode, message: "郵便番号" })) {
      return;
    }
    if (postalCode.length !== postalCodeLength) {
      alert(51);
      return;
    }
    getAddress.execute({ postalCode });
  };

  const getAddress = usePromise(
    useCallback(
      async ({ postalCode }: { postalCode: string }) => {
        const data: PostalCodeEntity | null = await webApi.fetch("postalcodes", { url: postalCode });

        if (data == null) {
          alert(36);
          return;
        }

        setInput((value) => {
          value.prefecture = data.prefecture;
          value.city = data.city;
          value.street = data.town;
          return { ...value };
        });
      },
      [alert, setInput]
    )
  );

  const handleOnClickDelete = useCallback((item: StaffEntity) => {
    return () => removeArrayState(setStaffs, (value) => item.recordId === value.recordId);
  }, []);

  const handleOnClickLink = (item: StaffEntity) => {
    return () => setStaff({ staff: item, displayMode: displayMode.Edit });
  };

  const validCorporateCategory = useCallback(
    (): boolean => input.data.corporateCategory !== corporateCategory.unselected,
    [input.data.corporateCategory]
  );

  const validCorporateId = useCallback((): boolean => {
    if (input.data.corporateCategory !== corporateCategory.corporation) {
      return true;
    }
    if (!isNullOrEmpty(input.data.corporateId)) {
      return true;
    }
    return false;
  }, [input.data]);

  const validateRegister = useCallback((): boolean => {
    if (
      !validateInputs(
        alert,
        { value: validCorporateCategory, message: "法人区分" },
        { value: validCorporateId, message: "法人番号" },
        { value: input.data.name, message: "事業者名" },
        { value: input.data.representativeName, message: "代表者氏名" },
        { value: input.data.representativeKana, message: "代表者氏名カナ" },
        { value: input.data.postalCode, message: "郵便番号" },
        { value: input.data.prefecture, message: "都道府県" },
        { value: input.data.city, message: "市区町村" },
        { value: input.data.accountId, message: "ユーザーID" },
        { value: input.data.password, message: "パスワード" },
        { value: input.data.passwordConfirm, message: "パスワード確認" }
      )
    ) {
      return false;
    }

    //パスワードをチェックする。
    if (input.data.password.length < minPasswordLength || input.data.password.length > maxPasswordLength) {
      alert(37);
      return false;
    }
    if (passwordRegex.test(input.data.password)) {
      alert(38);
      return false;
    }
    if (input.data.password !== input.data.passwordConfirm) {
      alert(26);
      return false;
    }

    // メールアドレスをチェックする。
    if (
      !emailFormatRegex.test(input.data.accountId) ||
      (input.data.saitamaPersonEmail != null && !emailFormatRegex.test(input.data.saitamaPersonEmail))
    ) {
      alert(19);
      return false;
    }

    if (
      input.data.corporateCategory === corporateCategory.corporation &&
      input.data.corporateId?.length !== corporateIdLength
    ) {
      alert(50);
      return false;
    }
    if (input.data.postalCode.length !== postalCodeLength) {
      alert(51);
      return false;
    }
    return true;
  }, [alert, input.data, validCorporateCategory, validCorporateId]);

  const validateUpdate = useCallback(() => {
    if (
      !validateInputs(
        alert,
        { value: validCorporateCategory, message: "法人区分" },
        { value: validCorporateId, message: "法人番号" },
        { value: input.data.representativeName, message: "代表者氏名" },
        { value: input.data.representativeKana, message: "代表者氏名カナ" },
        { value: input.data.postalCode, message: "郵便番号" },
        { value: input.data.prefecture, message: "都道府県" },
        { value: input.data.city, message: "市区町村" }
      )
    ) {
      return false;
    }

    if (
      input.data.corporateCategory === corporateCategory.corporation &&
      input.data.corporateId?.length !== corporateIdLength
    ) {
      alert(50);
      return false;
    }
    if (input.data.postalCode.length !== postalCodeLength) {
      alert(51);
      return false;
    }
    return true;
  }, [alert, input.data, validCorporateCategory, validCorporateId]);

  const onCompleted = useCallback(
    async (result: boolean) => {
      if (result) {
        await message(20);
        navigate("login");
      }
    },
    [message, navigate]
  );

  const register = usePromise(
    useCallback(
      async (args: { companyEntity: CompanyEntity & { passwordCofirm?: string }; staffEntities: StaffEntity[] }) => {
        const response = await webApi.post("companies", args);
        if (response?.code === serverErrorCodes.ExistingUserId) {
          alert(17);
          return false;
        } else if (response?.code === serverErrorCodes.ApplyingUserId) {
          alert(21);
          return false;
        }
        return true;
      },
      [alert]
    ),
    undefined,
    onCompleted
  );

  const update = usePromise(
    useCallback(
      async (args: { companyEntity: CompanyEntity & { passwordCofirm?: string }; staffEntities: StaffEntity[] }) => {
        const companyModel: CompanyModel = await webApi.post("companies/edit", args);
        const newAccount: ExternalAccount = {
          companyEntity: companyModel.companyEntity,
          staffEntities: companyModel.staffEntities,
        };
        dispatch({ type: AppActions.SET_ACCOUNT, value: newAccount });
        navigate("/main/company");
      },
      [navigate, dispatch]
    )
  );

  const handleOnClickRegister = async () => {
    if (editMode) {
      if (!validateUpdate()) {
        return;
      }
      if (!(await message(8, input.data.name))) {
        return;
      }

      update.execute({ companyEntity: input.data, staffEntities: staffs });
    } else {
      if (!validateRegister()) {
        return;
      }
      if (!(await message(8, input.data.name))) {
        return;
      }
      register.execute({ companyEntity: input.data, staffEntities: staffs });
    }
  };

  const corporateCategories: ComboItem[] = useMemo(
    () => master.comboItems(codeMasterGroupId.CorporateCategory),
    [master]
  );

  const columns: Array<Column<StaffEntity>> = useMemo(
    () => [
      createIconColumn(<Delete />, (item: StaffEntity) => handleOnClickDelete(item), "削除"),
      {
        header: "担当者名",
        width: 180,
        fix: true,
        cell: (item: StaffEntity): JSX.Element => (
          <CellGrid>
            <LikeLink css={genericStyles.marginLeft} onClick={handleOnClickLink(item)} text={item.name} />
          </CellGrid>
        ),
        sort: (a: StaffEntity, b: StaffEntity, asc: boolean) => generalComparsion(a.name, b.name) * (asc ? 1 : -1),
      },
      {
        header: "メールアドレス",
        cell: "email",
      },
      {
        header: "電話番号",
        width: 180,
        fix: true,
        cell: "tel",
      },
      {
        header: "所属名",
        cell: "department",
      },
      {
        header: "役職名",
        cell: "post",
      },
    ],
    [handleOnClickDelete]
  );

  const handleOnClickBack = () => {
    if (props.displayMode === displayMode.Register) {
      navigate("/login");
    } else if (props.displayMode === displayMode.Edit) {
      navigate("/main/company");
    }
  };

  const handleOnClickGBiz = () => {
    const host = master.systemParameter(systemParameterId.GBizHost) ?? "";
    const redirectUrl = process.env.REACT_APP_REDIRECT_URI_ACCOUNT ?? "";
    gBizLogin(host, redirectUrl);
  };

  return (
    <>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Box css={[appStyles.subTitleBox, styles.subTitleBox]}>
            <Typography css={appStyles.subTitle}>アカウント登録画面</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <Grid item xs={8} css={css({ marginTop: 0 })} container rowSpacing={3}>
            <Grid item xs={12} container justifyContent="flex-end" alignItems="center" columnSpacing={1}>
              {editMode && (
                <Grid item>
                  <Button css={appStyles.largeButton} variant="contained" onClick={handleOnClickGBiz}>
                    Gビズ情報取得
                  </Button>
                </Grid>
              )}
              {(props.displayMode === displayMode.Register || props.displayMode === displayMode.Edit) && (
                <Grid item>
                  <Button css={appStyles.middleButton} variant="contained" onClick={handleOnClickBack}>
                    戻る
                  </Button>
                </Grid>
              )}
            </Grid>
            <WithLabel visible={true} text={["法人：法人（団体含む）", "個人事業主：個人事業主等"]}>
              <Select
                label="法人区分"
                css={genericStyles.w100p}
                items={corporateCategories}
                value={input.data.corporateCategory}
                onChange={input.handleOnChangeSelect("corporateCategory")}
                required
              />
            </WithLabel>
            <WithLabel
              visible={!editMode}
              text={["法人の場合：法人名", "個人事業主の場合：屋号もしくは代表者氏名", "個人の場合：個人の氏名"]}
            >
              <TextField
                label="事業者名"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.name}
                onChange={input.handleOnChange("name")}
                readOnly={editMode}
                required={!editMode}
              />
            </WithLabel>
            <WithLabel>
              <TextField
                label="法人番号（13桁の半角数字）"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.corporateId}
                onChange={input.handleOnChange("corporateId")}
                required={input.data.corporateCategory === corporateCategory.corporation}
                onBlur={input.validate("corporateId", onlyNumberRegex)}
              />
            </WithLabel>
            <WithLabel>
              <TextField
                label="代表者氏名"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.representativeName}
                onChange={input.handleOnChange("representativeName")}
                required
              />
            </WithLabel>
            <WithLabel>
              <TextField
                label="代表者氏名カナ"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.representativeKana}
                onChange={input.handleOnChange("representativeKana")}
                required
              />
            </WithLabel>
            <WithLabel visible text={["役職がない場合や個人の場合は「なし」と記入してください。"]}>
              <TextField
                label="代表者役職名"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.representativePost}
                onChange={input.handleOnChange("representativePost")}
              />
            </WithLabel>
            <WithLabel
              visible
              text={[
                "登録事業者の郵便番号をハイフン無しの数字で入力してください。「住所を自動入力」ボタンを押下すると、住所が自動入力されます。",
              ]}
            >
              <Grid container justifyContent="flex-start" alignItems="flex-end">
                <Grid item css={css({ width: `calc(100% - ${Design.largeWidth + Design.margin}px)` })}>
                  <TextField
                    label="郵便番号"
                    css={genericStyles.w100p}
                    variant="standard"
                    value={input.data.postalCode}
                    onChange={input.handleOnChange("postalCode")}
                    required
                    onBlur={input.validate("postalCode", onlyNumberRegex)}
                  />
                </Grid>
                <Grid item css={genericStyles.marginLeft}>
                  <Button css={appStyles.largeButton} variant="contained" onClick={handleOnClickAddress}>
                    住所を自動入力
                  </Button>
                </Grid>
              </Grid>
            </WithLabel>
            <WithLabel>
              <TextField
                label="都道府県"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.prefecture}
                onChange={input.handleOnChange("prefecture")}
                required
              />
            </WithLabel>
            <WithLabel>
              <TextField
                label="市区町村"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.city}
                onChange={input.handleOnChange("city")}
                required
              />
            </WithLabel>
            <WithLabel>
              <TextField
                label="番地"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.street}
                onChange={input.handleOnChange("street")}
              />
            </WithLabel>
            <WithLabel>
              <TextField
                label="建物名・ビル名等"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.building}
                onChange={input.handleOnChange("building")}
              />
            </WithLabel>
            <WithLabel
              visible={!editMode}
              text={[
                `埼玉県財務受付ポータルではこのユーザID（メールアドレス）に対し、「${
                  master.systemParameter(systemParameterId.MailSender) ?? ""
                }」から登録案内等のメールをお送りします。`,
                "当該メールを受信できるよう事前に設定をお願いします。",
                "埼玉県財務受付ポータルへのログイン時に使用しますので、忘れることのないメールアドレスをご指定ください。",
              ]}
            >
              <TextField
                label="ユーザーID"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.accountId}
                onChange={input.handleOnChange("accountId")}
                required={!editMode}
                readOnly={editMode || fixAccountId}
                labelPosition="static"
                onBlur={input.validate("accountId", emailStringRegex)}
              />
            </WithLabel>
            {!editMode && (
              <>
                <WithLabel>
                  <TextField
                    label="パスワード"
                    css={genericStyles.w100p}
                    variant="standard"
                    value={input.data.password}
                    onChange={input.handleOnChange("password")}
                    required
                    type="password"
                    labelPosition="static"
                    onBlur={input.validate("password", passwordRegex)}
                  />
                </WithLabel>
                <WithLabel
                  visible
                  text={[
                    "パスワード及びパスワード確認は、半角英数記号で8文字以上20文字以内で任意の文字列が指定可能です。",
                    `使用可能な文字・記号：半角英数字、半角スペース、半角記号${halfWidthSymbols}`,
                    "埼玉県財務受付ポータルへのログイン時に使用しますので、忘れることのない文字列をご指定ください。",
                  ]}
                >
                  <TextField
                    label="パスワード確認"
                    css={genericStyles.w100p}
                    variant="standard"
                    value={input.data.passwordConfirm}
                    onChange={input.handleOnChange("passwordConfirm")}
                    required
                    type="password"
                    onBlur={input.validate("passwordConfirm", passwordRegex)}
                  />
                </WithLabel>
                <WithLabel
                  visible
                  text={["埼玉県の担当者のメールアドレスを入力してください。不明な場合は空白としてください。"]}
                >
                  <TextField
                    label="埼玉県担当者メールアドレス"
                    css={genericStyles.w100p}
                    variant="standard"
                    value={input.data.saitamaPersonEmail}
                    onChange={input.handleOnChange("saitamaPersonEmail")}
                    onBlur={input.validate("saitamaPersonEmail", emailStringRegex)}
                  />
                </WithLabel>
              </>
            )}
            <WithLabel>
              <TextField
                label="申請担当者名"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.applicantName}
                onChange={input.handleOnChange("applicantName")}
              />
            </WithLabel>
            <WithLabel>
              <TextField
                label="申請担当者名カナ"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.applicantKana}
                onChange={input.handleOnChange("applicantKana")}
              />
            </WithLabel>
            <WithLabel>
              <TextField
                label="申請者所属部署名"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.applicantDepartment}
                onChange={input.handleOnChange("applicantDepartment")}
              />
            </WithLabel>
            <Grid item xs={12} container columnSpacing={1}>
              <Grid item xs={6}>
                <TextField
                  label="申請者電話番号"
                  css={genericStyles.w100p}
                  variant="standard"
                  value={input.data.applicantTel}
                  onChange={input.handleOnChange("applicantTel")}
                  onBlur={input.validate("applicantTel", numberAndHyphenRegex)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="申請者FAX番号"
                  css={genericStyles.w100p}
                  variant="standard"
                  value={input.data.applicantFax}
                  onChange={input.handleOnChange("applicantFax")}
                  onBlur={input.validate("applicantFax", numberAndHyphenRegex)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container rowSpacing={1}>
              <Grid item xs={12}>
                <Paper>
                  <Table
                    columns={columns}
                    items={staffs}
                    onClickAdd={handleOnClickAddStaff}
                    stripe
                    height={styles.tableHeight}
                    maxHeight={styles.tableHeight}
                    options={{ defaultHeader }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  担当者の入力を＋ボタンから行ってください。
                  {!editMode && "（アカウント認証後に追加編集も可能となります）"}
                </Typography>
                <Typography variant="body2">担当者の情報を修正する場合は担当者名を選択してください。</Typography>
              </Grid>
            </Grid>
            {!editMode && (
              <Grid item xs={12} container alignItems="center" columnSpacing={1}>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={<Checkbox checked={checked} onClick={() => setChecked((c) => !c)} />}
                    label="利用規約に同意します"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Link
                    css={css({ cursor: "pointer" })}
                    href={undefined}
                    onClick={() => window.open(window.location.origin + "/terms")}
                  >
                    利用規約
                  </Link>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                css={appStyles.largeButton}
                disabled={!checked}
                onClick={handleOnClickRegister}
              >
                登録
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {staff != null && <StaffDialog staff={staff} staffs={staffs} onClose={handleOnClose} />}
    </>
  );
};

const WithLabel = ({
  children,
  visible,
  text,
}: {
  children: JSX.Element;
  visible?: boolean;
  text?: string[];
}): JSX.Element => {
  return (
    <Grid item xs={12} container columnSpacing={1} rowSpacing={1}>
      <Grid item xs={6}>
        {children}
      </Grid>
      {visible && (
        <Grid item xs={12}>
          {text?.map((value, index) => (
            <Typography key={index.toString()} variant="body2">
              {value}
            </Typography>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
