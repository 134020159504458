import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ExternalAccount } from "../models/ExternalAccount";
import { AppActions, AppProvider } from "../providers/AppProvider";
import { CookiePropertyName } from "../utilities/AppUtility";
import {
  CompanyEntity,
  deleteCookie,
  emptyUuid,
  getCookie,
  getTokenSilently,
  isNullOrEmpty,
  useAlertEx,
  webApi,
} from "lib-saitama";
import { UserInfoModel } from "../models/UserInfoModel";

interface Props {
  component: JSX.Element;
  to: string;
}

export const PublicRoute = (props: Props): JSX.Element => {
  const account = AppProvider.useGlobalState("account");

  const dispatch = AppProvider.useDispatch();

  useEffect(() => {
    if (account != null) {
      return;
    }

    void (async () => {
      try {
        if (!webApi.isJwt()) {
          await webApi.fetch("securities/login/silent/token/confirm");
          await getTokenSilently("securities/login/silent/token");
        }

        const newAccount: ExternalAccount = await webApi.fetch("Securities/login/silent/account", {
          onError: () => Promise.resolve(),
        });

        dispatch({ type: AppActions.SET_ACCOUNT, value: newAccount });
      } catch {
        deleteCookie(Object.values(CookiePropertyName));
        webApi.setJwt("");
      }
    })();
  }, [account, dispatch]);

  if (account == null) {
    return props.component;
  } else {
    return <Navigate to={props.to} />;
  }
};

export const PrivateRoute = (props: Props): JSX.Element => {
  const account = AppProvider.useGlobalState("account");

  if (account == null) {
    return <Navigate to={props.to} />;
  } else {
    return props.component;
  }
};

export const GBizRoute = ({ redirectUri }: { redirectUri: string }): JSX.Element => {
  const master = AppProvider.useGlobalState("master");

  const alert = useAlertEx(master.messages);

  const navigate = useNavigate();

  const dispatch = AppProvider.useDispatch();

  const search = useLocation().search;

  useEffect(() => {
    const failed = () => {
      deleteCookie(Object.values(CookiePropertyName));
      webApi.setJwt("");
      alert(64);
      dispatch({ type: AppActions.SET_ACCOUNT, value: undefined });
      navigate("/login");
    };

    const toCompanyEntity = (companyEntity: CompanyEntity, userInfoModel: UserInfoModel) => {
      companyEntity.accountId = userInfoModel.user_email;
      companyEntity.corporateCategory = Number.parseInt(userInfoModel.corp_type);
      companyEntity.name = userInfoModel.name;
      companyEntity.corporateId = userInfoModel.corporate_number;
      companyEntity.representativeName = userInfoModel.rep_last_nm + userInfoModel.rep_first_nm;
      companyEntity.representativeKana = userInfoModel.rep_last_nm_kana + userInfoModel.rep_first_nm_kana;
      companyEntity.prefecture = userInfoModel.prefecture_name;
      companyEntity.city = userInfoModel.address1;
      companyEntity.street = userInfoModel.address2;
      companyEntity.applicantName = userInfoModel.user_last_nm + userInfoModel.user_first_nm;
      companyEntity.applicantKana = userInfoModel.user_last_nm_kana + userInfoModel.user_first_nm_kana;
      companyEntity.applicantDepartment = userInfoModel.user_department;
      companyEntity.applicantTel = userInfoModel.user_tel_no_contact;
    };

    const query = new URLSearchParams(search);
    const code = query.get("code");
    const state = query.get("state");
    const cookieState = getCookie(CookiePropertyName.state);
    if (state != cookieState || isNullOrEmpty(code)) {
      failed();
      return;
    }

    void (async () => {
      try {
        const account: ExternalAccount = await webApi.fetch("securities/gbiz", {
          query: { code: code ?? "", redirectUri: redirectUri },
          onError: () => Promise.resolve(),
        });

        if (account.userInfoModel == null) {
          failed();
          return;
        }

        if (redirectUri === process.env.REACT_APP_REDIRECT_URI_LOGIN) {
          if (isNullOrEmpty(account.companyEntity.recordId) || account.companyEntity.recordId === emptyUuid()) {
            toCompanyEntity(account.companyEntity, account.userInfoModel);
            dispatch({ type: AppActions.SET_TRANSITION, value: account.companyEntity });
            navigate("/account");
          } else {
            dispatch({ type: AppActions.SET_ACCOUNT, value: account });
            navigate("/main/case");
          }
        } else {
          const companyEntity: CompanyEntity = { ...account.companyEntity };
          toCompanyEntity(companyEntity, account.userInfoModel);
          dispatch({ type: AppActions.SET_TRANSITION, value: companyEntity });
          dispatch({ type: AppActions.SET_ACCOUNT, value: account });
          navigate("/main/company/edit");
        }
      } catch {
        failed();
      }
    })();
  }, [alert, dispatch, navigate, redirectUri, search]);

  return <></>;
};
