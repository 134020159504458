/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { handleOnClickDownload, titleBackground } from "../../utilities/AppUtility";
import Paper from "@mui/material/Paper";
import {
  Area,
  Column,
  DisplayMode,
  FileDialog,
  FileEntity,
  Fetching,
  LoadingProps,
  Master,
  Table,
  TaskEntity,
  createIconColumn,
  defaultHeader,
  displayMode,
  commentColumn,
  uploadDateColumn,
} from "lib-saitama";
import { useCallback, useMemo, useState } from "react";
import CloudDownload from "@mui/icons-material/CloudDownload";

const styles = {
  subTitleBox: css({
    background: titleBackground,
  }),
  tableHeight: 300,
  loading: css({
    width: "100%",
    height: 248,
  }),
};

interface Props {
  fileEntities: FileEntity[];

  loading: LoadingProps;

  master: Master;
}

export const FromSaitama = ({ fileEntities, loading, master }: Props): JSX.Element => {
  const [file, setFile] = useState<{
    displayMode: DisplayMode;
    fileEntity: FileEntity;
    commit: boolean;
    taskEntity?: TaskEntity;
    onClose: (result?: FileEntity) => void;
  }>();

  const handleOnClickLink = useCallback((item: FileEntity) => {
    return () => {
      setFile({
        commit: true,
        displayMode: displayMode.Reference,
        fileEntity: item,
        onClose: () => setFile(undefined),
      });
    };
  }, []);

  const columns: Array<Column<FileEntity>> = useMemo(() => {
    return [
      createIconColumn(<CloudDownload />, handleOnClickDownload, "ダウンロード"),
      {
        width: 300,
        header: "ファイル名",
        cell: "name",
      },
      commentColumn(handleOnClickLink, 350),
      uploadDateColumn(),
    ];
  }, [handleOnClickLink]);

  return (
    <>
      <Area title="埼玉県送付ファイル一覧">
        <Fetching css={styles.loading} {...loading}>
          <Paper>
            <Table
              virtual
              recordKey="recordId"
              height={styles.tableHeight}
              maxHeight={styles.tableHeight}
              items={fileEntities}
              columns={columns}
              stripe
              options={{
                defaultHeader,
                initialSortState: { asc: false, column: columns[3] },
              }}
            />
          </Paper>
        </Fetching>
        {file != null && (
          <FileDialog
            displayMode={file.displayMode}
            commit={file.commit}
            fileEntity={file.fileEntity}
            onClose={file.onClose}
            master={master}
          />
        )}
      </Area>
    </>
  );
};
