/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { appStyles, Design } from "lib-saitama";
import { Constants, titleBackground } from "../../utilities/AppUtility";
import { AppActions, AppProvider } from "../../providers/AppProvider";
import { useEffect } from "react";

const styles = {
  appBar: css({
    background: titleBackground,
  }),
  title: css({
    textShadow: "3px 3px 4px rgba(107,73,61,1)",
  }),
};

export const Message = (): JSX.Element => {
  const dispatch = AppProvider.useDispatch();

  useEffect(() => {
    dispatch({ type: AppActions.SET_ACCOUNT, value: undefined });
  }, [dispatch]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" css={styles.appBar}>
          <Toolbar>
            <Typography css={[appStyles.title, styles.title]}>{Constants.title}</Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid
        sx={{
          height: `calc(100vh - ${Design.headerHeight}px)`,
        }}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        rowSpacing={4}
      >
        <Grid item>
          <Typography>システムエラーです。</Typography>
        </Grid>
        <Grid item>
          <Typography>
            ブラウザのページを閉じて、再度ログインを行ってください。サービス稼働時間外ではログイン出来ません。
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
