/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  Area,
  Column,
  CompanyEntity,
  Design,
  DisplayMode,
  FileDialog,
  FileEntity,
  Fetching,
  StaffEntity,
  Table,
  TextField,
  appStyles,
  codeMasterGroupId,
  createIconColumn,
  defaultHeader,
  displayMode,
  download,
  generateUuid,
  genericStyles,
  location,
  mergeArrayState,
  provider,
  removeArrayState,
  useMessageEx,
  usePromise,
  webApi,
  commentColumn,
  uploadDateColumn,
  providerColumn,
} from "lib-saitama";
import { titleBackground } from "../../utilities/AppUtility";
import { AppProvider } from "../../providers/AppProvider";
import { useCallback, useMemo, useState } from "react";
import { CompanyModel } from "../../models/CompanyModel";
import CloudDownload from "@mui/icons-material/CloudDownload";
import Delete from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

const styles = {
  subTitleBox: css({
    background: titleBackground,
  }),
  tableHeight: 300,
};

export const Company = (): JSX.Element => {
  const master = AppProvider.useGlobalState("master");

  const message = useMessageEx(master.messages);

  const navigate = useNavigate();

  const [companyEntity, setCompanyEntity] = useState<CompanyEntity>({
    recordId: "",
    companyId: "",
    accountId: "",
    password: "",
    corporateCategory: 0,
    name: "",
    representativeName: "",
    representativeKana: "",
    representativePost: "",
    postalCode: "",
    prefecture: "",
    city: "",
    approved: false,
    deleted: false,
    createdAt: new Date(),
    createdBy: "",
    updatedAt: new Date(),
    updatedBy: "",
  });

  const [staffEntities, setStaffEntities] = useState<StaffEntity[]>([]);

  const fetchCompany = usePromise(
    useCallback(async () => {
      const companyModel: CompanyModel = await webApi.fetch("companies");
      setCompanyEntity(companyModel.companyEntity);
      setStaffEntities(companyModel.staffEntities);
      setFileEntities(companyModel.fileEntities);
    }, [setCompanyEntity, setStaffEntities]),
    {}
  );

  const handleOnClickAdd = () => {
    setFile({
      displayMode: displayMode.Register,
      fileEntity: {
        recordId: generateUuid(),
        relatedRecordId: companyEntity.recordId,
        path: "",
        name: "",
        uploadedAt: new Date(),
        size: 0,
        location: location.Company,
        provider: provider.Company,
        related: false,
        deleted: false,
        createdAt: new Date(),
        createdBy: "",
        updatedAt: new Date(),
        updatedBy: "",
      },
    });
  };

  const [fileEntities, setFileEntities] = useState<FileEntity[]>([]);

  const [file, setFile] = useState<{ displayMode: DisplayMode; fileEntity: FileEntity }>();

  const staffColumns: Array<Column<StaffEntity>> = useMemo(() => {
    return [
      {
        header: "担当者名",
        cell: "name",
      },
      {
        header: "メールアドレス",
        cell: "email",
      },
      {
        header: "電話番号",
        cell: "tel",
      },
      {
        header: "所属名",
        cell: "department",
      },
      {
        header: "役職名",
        cell: "post",
      },
    ];
  }, []);

  const handleOnClose = (result?: FileEntity) => {
    if (result != null) {
      mergeArrayState(result, setFileEntities, (value) => value.recordId === result.recordId);
    }
    setFile(undefined);
  };

  const handleOnClickDownload = useCallback((item: FileEntity) => {
    return async () => {
      const response = await webApi.fetch("files/download", { url: item.recordId, outputFormat: "blob" });
      download(response, item.name);
    };
  }, []);

  const handleOnClickDelete = useCallback(
    (item: FileEntity) => {
      return async () => {
        if (!(await message(29, item.name))) {
          return;
        }

        await webApi.delete("files", { url: item.recordId });
        removeArrayState(setFileEntities, (value) => value.recordId === item.recordId);
      };
    },
    [message]
  );

  const handleOnClickLink = useCallback((item: FileEntity) => {
    return () => {
      setFile({
        displayMode: item.provider === provider.Company ? displayMode.Edit : displayMode.Reference,
        fileEntity: item,
      });
    };
  }, []);

  const fileColumns: Array<Column<FileEntity>> = useMemo(() => {
    return [
      createIconColumn(<CloudDownload />, handleOnClickDownload, "ダウンロード"),
      createIconColumn(<Delete />, handleOnClickDelete, "削除", (item) => item.provider === provider.Saitama),
      {
        header: "ファイル名",
        cell: "name",
      },
      providerColumn(master),
      commentColumn(handleOnClickLink),
      uploadDateColumn(),
    ];
  }, [master, handleOnClickDownload, handleOnClickDelete, handleOnClickLink]);

  return (
    <>
      <Fetching {...fetchCompany.loading}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" rowSpacing={2}>
          <Grid item xs={12}>
            <Box css={[appStyles.subTitleBox, styles.subTitleBox]}>
              <Typography css={appStyles.subTitle}>事業者情報詳細</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} container direction="row">
            <Grid item container justifyContent="flex-end" columnSpacing={1}>
              <Grid item>
                <Button css={appStyles.middleButton} variant="contained" onClick={() => navigate("/main/company/edit")}>
                  編集
                </Button>
              </Grid>
              <Grid item>
                <Button css={appStyles.middleButton} variant="contained" onClick={() => navigate("/main/case")}>
                  案件一覧
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Area title="事業者情報" contentCss={css({ padding: Design.margin })}>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item xs={3}>
                  <TextField
                    css={genericStyles.w100p}
                    label="事業者ID"
                    value={companyEntity.companyId}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    css={genericStyles.w100p}
                    label="法人区分"
                    value={master.displayText(codeMasterGroupId.CorporateCategory, companyEntity.corporateCategory)}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField css={genericStyles.w100p} label="事業者名" value={companyEntity.name} readOnly={true} />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    css={genericStyles.w100p}
                    label="法人番号"
                    value={companyEntity.corporateId}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    css={genericStyles.w100p}
                    label="代表者氏名"
                    value={companyEntity.representativeName}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    css={genericStyles.w100p}
                    label="代表者氏名カナ"
                    value={companyEntity.representativeKana}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    css={genericStyles.w100p}
                    label="代表者役職名"
                    value={companyEntity.representativePost}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    css={genericStyles.w100p}
                    label="ユーザーID"
                    value={companyEntity.accountId}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    css={genericStyles.w100p}
                    label="住所"
                    value={`〒${companyEntity.postalCode}  ${companyEntity.prefecture}${companyEntity.city}${
                      companyEntity.street ?? ""
                    }${companyEntity.building ?? ""}`}
                    readOnly={true}
                  />
                </Grid>
              </Grid>
            </Area>
          </Grid>
          <Grid item xs={12}>
            <Area title="申請者情報" contentCss={css({ padding: Design.margin })}>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item xs={3}>
                  <TextField
                    css={genericStyles.w100p}
                    label="申請者名"
                    value={companyEntity.applicantName}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    css={genericStyles.w100p}
                    label="申請者名カナ"
                    value={companyEntity.applicantKana}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    css={genericStyles.w100p}
                    label="申請者所属部署名"
                    value={companyEntity.applicantDepartment}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                  <TextField
                    css={genericStyles.w100p}
                    label="申請者電話番号"
                    value={companyEntity.applicantTel}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    css={genericStyles.w100p}
                    label="申請者FAX番号"
                    value={companyEntity.applicantFax}
                    readOnly={true}
                  />
                </Grid>
              </Grid>
            </Area>
          </Grid>
          <Grid item xs={12}>
            <Area title="担当者情報" remark="（担当者を編集する場合は、編集ボタンを押下してください。）">
              <Paper>
                <Table
                  recordKey="recordId"
                  height={styles.tableHeight}
                  maxHeight={styles.tableHeight}
                  items={staffEntities}
                  columns={staffColumns}
                  stripe
                  options={{ defaultHeader }}
                />
              </Paper>
            </Area>
          </Grid>
          <Grid item xs={12}>
            <Area
              title="ファイルの提出"
              remark="（＋ボタンから提出するファイルを選択し、ファイルの提出をしてください。）"
            >
              <Paper>
                <Table
                  virtual
                  recordKey="recordId"
                  height={styles.tableHeight}
                  maxHeight={styles.tableHeight}
                  items={fileEntities}
                  columns={fileColumns}
                  onClickAdd={handleOnClickAdd}
                  stripe
                  options={{
                    defaultHeader,
                    initialSortState: { asc: false, column: fileColumns[5] },
                  }}
                />
              </Paper>
            </Area>
          </Grid>
        </Grid>
      </Fetching>
      {file != null && (
        <FileDialog
          displayMode={file.displayMode}
          commit={true}
          fileEntity={file.fileEntity}
          onClose={handleOnClose}
          master={master}
        />
      )}
    </>
  );
};
