import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import { AppProvider } from "../../providers/AppProvider";
import {
  DialogActions,
  DialogContent,
  DisplayMode,
  StaffEntity,
  TextField,
  appStyles,
  displayMode,
  genericStyles,
  useAlertEx,
  useInputManager,
  validateInputs,
} from "lib-saitama";
import { emailFormatRegex, emailStringRegex, numberAndHyphenRegex } from "../../utilities/AppUtility";

interface Props {
  staff: { staff: StaffEntity; displayMode: DisplayMode };
  staffs: StaffEntity[];
  onClose: (staff?: StaffEntity) => void;
}

export const StaffDialog = (props: Props): JSX.Element => {
  const master = AppProvider.useGlobalState("master");

  const alert = useAlertEx(master.messages);

  const [input] = useInputManager<StaffEntity & { emailConfirm?: string }>(props.staff.staff);

  const handleOnClickOk = () => {
    if (validate()) {
      props.onClose(input.data);
    }
  };

  const isEmailExist = useCallback(
    (): boolean =>
      props.staffs
        .filter((s) => s.recordId !== input.data.recordId)
        .map((s) => s.email)
        .includes(input.data.email),
    [props.staffs, input.data]
  );

  const validate = useCallback((): boolean => {
    // 必須項目のチェックを行う。
    if (
      !validateInputs(
        alert,
        { value: input.data.name, message: "担当者名" },
        { value: input.data.email, message: "メールアドレス" },
        { value: input.data.emailConfirm, message: "メールアドレス（再入力）" }
      )
    ) {
      return false;
    }

    // メールアドレスのチェックを行う
    if (input.data.email !== input.data.emailConfirm) {
      alert(41);
      return false;
    }

    if (!emailFormatRegex.test(input.data.email)) {
      alert(19);
      return false;
    }

    if (isEmailExist()) {
      alert(18);
      return false;
    }

    return true;
  }, [alert, isEmailExist, input.data]);

  return (
    <>
      <Dialog open={true} fullWidth={true} maxWidth="sm">
        <DialogTitle>担当者{props.staff.displayMode === displayMode.Edit ? "編集" : "登録"}</DialogTitle>
        <DialogContent>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" rowSpacing={4}>
            <Grid item xs={12}>
              <TextField
                label="担当者名"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.name}
                onChange={input.handleOnChange("name")}
                required
              />
            </Grid>
            <Grid item xs={12} container rowSpacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="メールアドレス"
                  css={genericStyles.w100p}
                  variant="standard"
                  value={input.data.email}
                  onChange={input.handleOnChange("email")}
                  required
                  onBlur={input.validate("email", emailStringRegex)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  埼玉県からの通知があった場合、このメールアドレスに通知されます。
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="メールアドレス（再入力）"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.emailConfirm}
                onChange={input.handleOnChange("emailConfirm")}
                required
                onBlur={input.validate("emailConfirm", emailStringRegex)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="電話番号"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.tel}
                onChange={input.handleOnChange("tel")}
                onBlur={input.validate("tel", numberAndHyphenRegex)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="所属名"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.department}
                onChange={input.handleOnChange("department")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="役職名"
                css={genericStyles.w100p}
                variant="standard"
                value={input.data.post}
                onChange={input.handleOnChange("post")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button css={appStyles.middleButton} color="primary" variant="contained" onClick={handleOnClickOk}>
            登録
          </Button>
          <Button css={appStyles.middleButton} color="primary" variant="contained" onClick={() => props.onClose()}>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
