/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextField, appStyles, useAlertEx, useMessageEx, usePromise, validateInputs, webApi } from "lib-saitama";
import { titleBackground } from "../../utilities/AppUtility";
import { useCallback, useState } from "react";
import { AppProvider } from "../../providers/AppProvider";
import { useNavigate } from "react-router-dom";

const styles = {
  subTitleBox: css({
    background: titleBackground,
  }),
};

export const Reissue = (): JSX.Element => {
  const master = AppProvider.useGlobalState("master");

  const navigate = useNavigate();

  const alert = useAlertEx(master.messages);

  const message = useMessageEx(master.messages);

  const [email, setEmail] = useState<string>("");

  const handleOnClick = () => {
    if (!validateInputs(alert, { value: email, message: "メールアドレス" })) {
      return;
    }
    submit.execute({ email: email });
  };

  const onCompleted = useCallback(async () => {
    await message(25);
    navigate("login");
  }, [message, navigate]);

  const submit = usePromise(
    useCallback(async (args: { email: string }) => {
      await webApi.post("companies/passwords", args);
    }, []),
    undefined,
    onCompleted
  );

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" rowSpacing={1}>
      <Grid item xs={12}>
        <Box css={[appStyles.subTitleBox, styles.subTitleBox]}>
          <Typography css={appStyles.subTitle}>パスワード変更通知</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center" rowSpacing={6}>
        <Grid item css={css({ marginTop: 150 })}>
          <Typography>パスワード変更の通知を行います。</Typography>
          <Typography> 変更するアカウントのユーザーID（メールアドレス）を入力してください。</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center" alignItems="center" columnSpacing={2}>
          <Grid item css={css({ width: 800 })}>
            <TextField label="ユーザーID（メールアドレス）" value={email} onChange={(e) => setEmail(e.target.value)} />
          </Grid>
          <Grid item>
            <Button variant="contained" css={css({ marginTop: 8 })} onClick={handleOnClick}>
              送信
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center" css={css({ marginTop: 150 })}>
        <Grid item>
          <Button variant="contained" onClick={() => navigate("login")}>
            ログイン画面へ
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
