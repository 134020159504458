/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { CaseSummaryModel, mergeArrayState } from "lib-saitama";
import { Case } from "./Case";
import { Cases } from "./Cases";

const styles = {
  show: (show: boolean) =>
    css({
      display: show ? undefined : "none",
    }),
};

export const CasePage = (): JSX.Element => {
  const [caseSummaryModel, setCaseSummaryModel] = useState<CaseSummaryModel | undefined>(undefined);

  const [listData, setListData] = useState<CaseSummaryModel[]>([]);

  const onClose = (result?: CaseSummaryModel): void => {
    if (result != null) {
      mergeArrayState(result, setListData, (value) => value.recordId === result.recordId);
    }
    setCaseSummaryModel(undefined);
  };

  return (
    <>
      {caseSummaryModel != null && <Case caseSummaryModel={caseSummaryModel} onClose={onClose} />}
      <div css={styles.show(caseSummaryModel == null)}>
        <Cases setCaseSummaryModel={setCaseSummaryModel} listData={listData} setListData={setListData} />
      </div>
    </>
  );
};
