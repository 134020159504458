/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useState } from "react";
import {
  Fetching,
  TextField,
  appStyles,
  serverErrorCodes,
  useAlertEx,
  useInputManager,
  usePromise,
  validateInputs,
  webApi,
} from "lib-saitama";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  emailStringRegex,
  halfWidthSymbols,
  maxPasswordLength,
  minPasswordLength,
  passwordRegex,
  titleBackground,
} from "../../utilities/AppUtility";
import { AppProvider } from "../../providers/AppProvider";

const styles = {
  subTitleBox: css({
    background: titleBackground,
  }),
  loading: css({
    width: "100%",
    height: 500,
  }),
};

interface MailModel {
  email: string;
  password: string;
  passwordConfirm: string;
}

export const Resetting = (): JSX.Element => {
  const { id } = useParams();

  const master = AppProvider.useGlobalState("master");

  const navigate = useNavigate();

  const alert = useAlertEx(master.messages);

  const [input] = useInputManager<MailModel>({
    email: "",
    password: "",
    passwordConfirm: "",
  });

  const [message, setMessage] = useState<{ title: string; message: JSX.Element }>();

  const promise = usePromise(
    useCallback(async ({ id }: { id?: string }) => {
      const response = await webApi.fetch("companies/passwords/validate", { url: id });
      if (response == null) {
        return;
      } else if (response.code === serverErrorCodes.InvalidUrl) {
        setMessage({
          title: "パスワード変更エラー",
          message: (
            <>
              <Typography>URLが無効です。</Typography>
              <Typography>再度パスワード変更の手続を行ってください。</Typography>
            </>
          ),
        });
      } else if (response.code === serverErrorCodes.PasswordExpiredUrl) {
        setMessage({
          title: "パスワード変更エラー",
          message: (
            <>
              <Typography>URLの有効期限が切れています。</Typography>
              <Typography>再度パスワード変更の手続を行ってください。</Typography>
            </>
          ),
        });
      }
    }, []),
    { id }
  );

  const handleOnClick = () => {
    if (!validate(input.data)) {
      return;
    }
    if (id == null) {
      return;
    }
    register.execute({ recordId: id, email: input.data.email, password: input.data.password });
  };

  const validate = ({ email, password, passwordConfirm }: MailModel): boolean => {
    if (
      !validateInputs(
        alert,
        { value: email, message: "ユーザーID（メールアドレス）" },
        { value: password, message: "パスワード" },
        { value: passwordConfirm, message: "パスワード確認" }
      )
    ) {
      return false;
    }

    if (password.length < minPasswordLength || password.length > maxPasswordLength) {
      alert(37);
      return false;
    }
    if (passwordRegex.test(password)) {
      alert(38);
      return false;
    }
    if (password !== passwordConfirm) {
      alert(26);
      return false;
    }

    return true;
  };

  const register = usePromise(
    useCallback(
      async ({ recordId, email, password }: { recordId: string; email: string; password: string }) => {
        const response = await webApi.post("companies/passwords", { email, password }, { url: recordId });
        if (response == null) {
          setMessage({
            title: "パスワード変更完了",
            message: (
              <>
                <Typography>
                  パスワード変更が完了しました。「ログイン画面へ」をクリックし、ログインを行ってください。
                </Typography>
              </>
            ),
          });
        } else if (response.code === serverErrorCodes.InvalidUserId) {
          alert(24);
        } else if (response.code === serverErrorCodes.InvalidUrl) {
          setMessage({
            title: "パスワード変更エラー",
            message: (
              <>
                <Typography>URLが無効です。</Typography>
                <Typography>再度パスワード変更の手続きを行ってください。</Typography>
              </>
            ),
          });
        } else if (response.code === serverErrorCodes.PasswordExpiredUrl) {
          setMessage({
            title: "パスワード変更エラー",
            message: (
              <>
                <Typography>URLの有効期限が切れています。</Typography>
                <Typography>再度パスワード変更の手続きを行ってください。</Typography>
              </>
            ),
          });
        }
      },
      [alert]
    )
  );

  if (message != null) {
    return (
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" rowSpacing={1}>
        <Grid item xs={12}>
          <Box css={[appStyles.subTitleBox, styles.subTitleBox]}>
            <Typography css={appStyles.subTitle}>{message.title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          <Grid item xs={12} container justifyContent="center" css={css({ marginTop: 200 })}>
            <Grid item>{message.message}</Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="center" css={css({ marginTop: 150 })}>
            <Grid item>
              <Button variant="contained" onClick={() => navigate("/login")}>
                ログイン画面へ
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Fetching css={styles.loading} {...promise.loading}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" rowSpacing={1}>
        <Grid item xs={12}>
          <Box css={[appStyles.subTitleBox, styles.subTitleBox]}>
            <Typography css={appStyles.subTitle}>パスワード変更</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} container justifyContent="center" alignItems="center" rowSpacing={6}>
          <Grid item xs={12} container justifyContent="center" alignItems="center" css={css({ marginTop: 150 })}>
            <Grid item>
              <Typography>
                パスワードを変更します。ユーザーID（メールアドレス）と新しいパスワードを入力してください。
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} css={css({ width: 800 })} container rowSpacing={3}>
            <Grid item xs={12}>
              <TextField
                label="ユーザーID（メールアドレス）"
                value={input.data.email}
                onChange={input.handleOnChange("email")}
                required
                onBlur={input.validate("email", emailStringRegex)}
                labelPosition="static"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="パスワード"
                value={input.data.password}
                onChange={input.handleOnChange("password")}
                required
                type="password"
                onBlur={input.validate("password", passwordRegex)}
                labelPosition="static"
              />
            </Grid>
            <Grid item xs={12} container rowSpacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="パスワード確認"
                  value={input.data.passwordConfirm}
                  onChange={input.handleOnChange("passwordConfirm")}
                  required
                  type="password"
                  onBlur={input.validate("passwordConfirm", passwordRegex)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">パスワード及びパスワード確認</Typography>
                <Typography variant="body2">
                  パスワード及びパスワード確認は、半角英数記号で8文字以上20文字以内で任意の文字列が指定可能です。
                </Typography>
                <Typography variant="body2">
                  使用可能な文字・記号：半角英数字、半角スペース、半角記号{halfWidthSymbols}
                </Typography>
                <Typography variant="body2">
                  埼玉県財務受付ポータルへのログイン時に使用しますので、忘れることのない文字列をご指定ください。
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Grid item>
              <Button variant="contained" css={(css({ marginTop: 8 }), appStyles.largeButton)} onClick={handleOnClick}>
                登録
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fetching>
  );
};
