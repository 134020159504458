/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  Area,
  CaseSummaryModel,
  Column,
  Design,
  LikeLink,
  Fetching,
  OrganizationEntity,
  Select,
  SelectDialog,
  SelectField,
  SelectOrganizationDialog,
  SetState,
  StaffEntity,
  Table,
  TextField,
  appStyles,
  convertToFiscalYear,
  createFiscalItems,
  defaultHeader,
  fiscal,
  generalComparsion,
  genericStyles,
  useAlertEx,
  useInputManager,
  useNarrowDown,
  usePromise,
  webApi,
  CellGrid,
  systemParameterId,
} from "lib-saitama";
import { staffColumns, titleBackground } from "../../utilities/AppUtility";
import { AppProvider } from "../../providers/AppProvider";
import { useCallback, useMemo } from "react";

const styles = {
  subTitleBox: css({
    background: titleBackground,
  }),
  tableHeight: 540,
};

interface Condition {
  companyRecordId?: string;
  fiscalYear: number;
  caseId?: string;
  caseName?: string;
  organization?: OrganizationEntity;
  staff?: StaffEntity;
  uncompleted: boolean;
  completed: boolean;
}

const condition = (): Condition => {
  return {
    fiscalYear: fiscal(new Date()),
    uncompleted: true,
    completed: false,
  };
};

interface Props {
  setCaseSummaryModel: SetState<CaseSummaryModel | undefined>;

  listData: CaseSummaryModel[];

  setListData: SetState<CaseSummaryModel[]>;
}

export const Cases = (props: Props): JSX.Element => {
  const master = AppProvider.useGlobalState("master");

  const account = AppProvider.useGlobalState("account");

  const alert = useAlertEx(master.messages);

  const setListData = useMemo(() => props.setListData, [props.setListData]);

  const [input, setData] = useInputManager(condition());

  const handleOnClickClear = (): void => {
    setData(condition());
  };

  const currentCondition = useMemo(() => {
    return {
      fiscalYear: input.data.fiscalYear,
      caseId: input.data.caseId,
      caseName: input.data.caseName,
      organizationCode: input.data.organization?.organizationCode,
      staffRecordId: input.data.staff?.recordId,
      uncompleted: input.data.uncompleted,
      completed: input.data.completed,
    };
  }, [input.data]);

  const handleOnClickSearch = (): void => {
    search.execute(currentCondition);
  };

  usePromise(
    useCallback(
      async (args: object) => {
        const response: CaseSummaryModel[] = await webApi.post("cases/search", args);

        const max = master.getMaxRows(systemParameterId.ExternalCaseList);

        if (response.length > max) {
          alert(6, max.toString());
          response.pop();
        }

        setListData(response);
      },
      [setListData, master, alert]
    ),
    { ...condition() }
  );

  const search = usePromise(
    useCallback(
      async (args: object) => {
        const response: CaseSummaryModel[] = await webApi.post("cases/search", args);

        if (response.length === 0) {
          alert(7);
        }

        const max = master.getMaxRows(systemParameterId.ExternalCaseList);

        if (response.length > max) {
          alert(6, max.toString());
          response.pop();
        }

        setListData(response);
      },
      [setListData, master, alert]
    )
  );

  const setCaseSummaryModel = useMemo(() => props.setCaseSummaryModel, [props.setCaseSummaryModel]);

  const handleOnClickLink = useCallback(
    (item: CaseSummaryModel): (() => void) => {
      return () => {
        setCaseSummaryModel(item);
      };
    },
    [setCaseSummaryModel]
  );

  const columns: Array<Column<CaseSummaryModel>> = useMemo(() => {
    return [
      {
        width: 150,
        fix: true,
        header: "案件ID",
        bodyAlign: "center",
        cell: "id",
      },
      {
        width: 150,
        fix: true,
        header: "年度",
        bodyAlign: "center",
        cell: (item: CaseSummaryModel) => convertToFiscalYear(item.periodFrom, 0, master.eraNames),
        sort: (a: CaseSummaryModel, b: CaseSummaryModel, asc: boolean) =>
          generalComparsion(a.periodFrom, b.periodFrom) * (asc ? 1 : -1),
      },
      {
        width: 400,
        header: "案件名",
        cell: (item: CaseSummaryModel): JSX.Element => (
          <CellGrid>
            <LikeLink css={genericStyles.marginLeft} onClick={handleOnClickLink(item)} text={item.name} />
          </CellGrid>
        ),
        sort: (a: CaseSummaryModel, b: CaseSummaryModel, asc: boolean) =>
          generalComparsion(a.name, b.name) * (asc ? 1 : -1),
      },
      {
        width: 150,
        fix: true,
        header: "作業状況",
        bodyAlign: "center",
        cell: "status",
      },
      {
        width: 200,
        header: "埼玉県所属名",
        cell: "formalOrgName",
      },
      {
        width: 200,
        fix: true,
        header: "埼玉県担当者名",
        cell: "nameKanji",
      },
      {
        width: 200,
        header: "事業者担当者名1",
        cell: "mainStaffName",
      },
      {
        width: 200,
        header: "事業者担当者名2",
        cell: "subStaffName",
      },
    ];
  }, [handleOnClickLink, master.eraNames]);

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" rowSpacing={2}>
        <Grid item xs={12}>
          <Box css={[appStyles.subTitleBox, styles.subTitleBox]}>
            <Typography css={appStyles.subTitle}>調達案件一覧</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Area title="検索条件" contentCss={genericStyles.padding}>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              columnSpacing={1}
            >
              <Grid item>
                <Select
                  css={appStyles.unitWidth}
                  label="年度"
                  value={input.data.fiscalYear}
                  onChange={input.handleOnChangeSelect("fiscalYear")}
                  items={createFiscalItems(master.eraNames, 5)}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="案件ID"
                  css={appStyles.unitWidth}
                  value={input.data.caseId}
                  onChange={input.handleOnChange("caseId")}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="案件名"
                  css={css({ width: Design.unitWidth * 2 + Design.margin })}
                  value={input.data.caseName}
                  onChange={input.handleOnChange("caseName")}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              rowSpacing={1}
            >
              <Grid
                item
                container
                css={css({ width: (Design.unitWidth + Design.margin) * 3 })}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                columnSpacing={1}
              >
                <Grid item>
                  <SelectField
                    css={appStyles.unitWidth}
                    label="埼玉県所属名"
                    value={input.data.organization?.formalOrgName}
                    onChange={input.handleOnChangeSelectField("organization")}
                    onClear={input.handleOnClear("organization")}
                  >
                    <SelectOrganizationDialog messages={master.messages} />
                  </SelectField>
                </Grid>
                <Grid item>
                  <SelectField
                    css={appStyles.unitWidth}
                    label="事業者担当者名"
                    value={input.data.staff?.name}
                    onChange={input.handleOnChangeSelectField("staff")}
                    onClear={input.handleOnClear("staff")}
                  >
                    <SelectDialog
                      title="担当者"
                      columns={staffColumns}
                      onNarrowDown={useNarrowDown(true, "name")}
                      items={account?.staffEntities ?? []}
                    />
                  </SelectField>
                </Grid>
                <Grid item>
                  <Grid
                    css={css({
                      width: Design.unitWidth,
                      minWidth: Design.unitWidth,
                      height: Design.unitHeight,
                      padding: Design.margin,
                    })}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography>処理区分</Typography>
                    <FormControlLabel
                      css={css({ marginLeft: 0 })}
                      control={
                        <Checkbox
                          css={css({ paddingTop: 7 })}
                          checked={input.data.uncompleted}
                          onChange={input.handleOnChangeCheck("uncompleted")}
                        />
                      }
                      label="未完了"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={input.data.completed} onChange={input.handleOnChangeCheck("completed")} />
                      }
                      label="完了"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                css={css({ width: (152 + Design.margin) * 2, paddingTop: Design.margin })}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                columnSpacing={1}
              >
                <Grid item>
                  <Button css={appStyles.largeButton} variant="contained" onClick={handleOnClickClear}>
                    初期条件に戻す
                  </Button>
                </Grid>
                <Grid item>
                  <Button css={appStyles.largeButton} variant="contained" onClick={handleOnClickSearch}>
                    案件検索
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Area>
        </Grid>
        <Grid item xs={12}>
          <Area title="検索結果一覧">
            <Fetching css={css({ width: "100%", height: styles.tableHeight })} {...search.loading}>
              <Paper>
                <Table
                  virtual
                  recordKey="recordId"
                  height={styles.tableHeight}
                  maxHeight={styles.tableHeight}
                  items={props.listData}
                  columns={columns}
                  stripe
                  options={{
                    defaultHeader,
                    initialSortState: { asc: false, column: columns[0] },
                  }}
                />
              </Paper>
            </Fetching>
          </Area>
        </Grid>
      </Grid>
    </>
  );
};
