import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { Helmet, HelmetProvider } from "react-helmet-async";

const csp = "object-src 'none';script-src 'none';base-uri 'none';";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <HelmetProvider>
    <Helmet>
      <meta httpEquiv="Content-Security-Policy" content={csp} />
    </Helmet>
    <App />
  </HelmetProvider>
);
