/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useLayoutEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { AppActions, AppProvider } from "./providers/AppProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { BrowserRouter, Navigate, NavigateFunction, Route, Routes, useNavigate } from "react-router-dom";
import ja from "date-fns/locale/ja";
import { Constants, CookiePropertyName, titleBackground } from "./utilities/AppUtility";
import { GBizRoute, PrivateRoute, PublicRoute } from "./components/PrivateRoute";
import { Login } from "./pages/login/Login";
import {
  AlertList,
  AlertProvider,
  Master,
  usePromise,
  useAlert,
  Loading,
  webApi,
  displayMode,
  LoadingProvider,
  Fetching,
  Design,
  deleteCookie,
} from "lib-saitama";
import { Account } from "./pages/account/Account";
import { PrivateAppBar } from "./components/PrivateAppBar";
import { PublicAppBar } from "./components/PublicAppBar";
import { AccountComplete } from "./pages/account/AccountComplete";
import { Terms } from "./pages/account/Terms";
import { Company } from "./pages/company/Company";
import { CasePage } from "./pages/case/CasePage";
import { Reissue } from "./pages/password/Reissue";
import { Resetting } from "./pages/password/Resetting";
import { Message } from "./pages/message/Message";

const styles = {
  fetching: css({ height: "calc(100vh - 16px)", width: "calc(100% - 16px)", margin: Design.margin }),
};

const AppMain = (): JSX.Element => {
  const account = AppProvider.useGlobalState("account");

  const navigate = useNavigate();

  const dispatch = AppProvider.useDispatch();

  const alert = useAlert();

  const promise = usePromise(
    useCallback(
      async (args: { navigate: NavigateFunction }) => {
        try {
          const newMaster = await webApi.fetch("masters");

          dispatch({
            type: AppActions.SET_MASTERS,
            value: new Master(
              newMaster.users,
              newMaster.eraNames,
              newMaster.messages,
              newMaster.codes,
              newMaster.systems,
              newMaster.tasks,
              newMaster.systemParameters,
              newMaster.taxEntities
            ),
          });
        } catch {
          alert({ type: "error", message: "マスター情報の取得に失敗しました。再度ログインしてください。" });
          deleteCookie(Object.values(CookiePropertyName));
          webApi.setJwt("");
          args.navigate("/message");
          throw "";
        }
      },
      [alert, dispatch]
    ),
    { navigate }
  );

  return (
    <Fetching {...promise.loading}>
      <CssBaseline />
      <PrivateAppBar
        title={Constants.title}
        background={titleBackground}
        companyName={account?.companyEntity?.name ?? ""}
        representativeName={account?.companyEntity?.representativeName ?? ""}
        defaultOpen={true}
      >
        <Routes>
          <Route path="case" element={<CasePage />} />
          <Route path="company" element={<Company />} />
          <Route path="company/edit" element={<Account displayMode={displayMode.Edit} />} />
        </Routes>
      </PrivateAppBar>
    </Fetching>
  );
};

const AppRoute = (): JSX.Element => {
  const dispatch = AppProvider.useDispatch();

  const alert = useAlert();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    webApi.onSuccess = (response: Response) => {
      const authorization = response.headers.get("Authorization");
      if (authorization != null) {
        webApi.setJwt(authorization.slice(7));
      }
    };

    webApi.onError = async (response: Response) => {
      if (response.status === 401) {
        dispatch({ type: AppActions.SET_ACCOUNT, value: undefined });
        return;
      }

      try {
        const result = await response.text();
        const json = JSON.parse(result);
        if (json.code == null) {
          alert({ type: "error", message: `ステータスコード：${json.status} ${json.title}` });
        } else {
          alert({ type: "error", message: `エラーコード：${json.code} ${json.message}` });
        }
      } catch {
        alert({ type: "error", message: `通信処理に失敗しました。エラーコード：${response.status}` });
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    webApi.onException = (error: any) => {
      console.log(error);
      alert({ type: "error", message: "通信処理に失敗しました。エラーコード：exception" });
    };
  }, [alert, dispatch]);

  const promise = usePromise(
    useCallback(
      async (args: { navigate: NavigateFunction }) => {
        try {
          const newMaster = await webApi.fetch("masters/public");

          dispatch({
            type: AppActions.SET_MASTERS,
            value: new Master(
              newMaster.users,
              newMaster.eraNames,
              newMaster.messages,
              newMaster.codes,
              newMaster.systems,
              newMaster.tasks,
              newMaster.systemParameters,
              newMaster.taxEntities
            ),
          });
        } catch {
          alert({ type: "error", message: "マスター情報の取得に失敗しました。" });
          args.navigate("/message");
          throw "";
        }
      },
      [alert, dispatch]
    ),
    { navigate }
  );

  return (
    <Fetching css={styles.fetching} {...promise.loading}>
      <Routes>
        <Route
          path="/gbiz/login"
          element={<GBizRoute redirectUri={process.env.REACT_APP_REDIRECT_URI_LOGIN ?? ""} />}
        />
        <Route
          path="/gbiz/account"
          element={<GBizRoute redirectUri={process.env.REACT_APP_REDIRECT_URI_ACCOUNT ?? ""} />}
        />
        <Route path="/login" element={<PublicRoute component={<Login />} to="/main/case" />} />
        <Route path="/main/*" element={<PrivateRoute component={<AppMain />} to="/login" />} />
        <Route
          path="/account"
          element={
            <PublicAppBar title={Constants.title} background={titleBackground}>
              <Account displayMode={displayMode.Register} />
            </PublicAppBar>
          }
        />
        <Route
          path="/account/complete/:id"
          element={
            <PublicAppBar title={Constants.title} background={titleBackground}>
              <AccountComplete />
            </PublicAppBar>
          }
        />
        <Route
          path="/terms"
          element={
            <PublicAppBar title={Constants.title} background={titleBackground}>
              <Terms />
            </PublicAppBar>
          }
        />
        <Route
          path="/password/reissue"
          element={
            <PublicAppBar title={Constants.title} background={titleBackground}>
              <Reissue />
            </PublicAppBar>
          }
        />
        <Route
          path="/password/resetting/:id"
          element={
            <PublicAppBar title={Constants.title} background={titleBackground}>
              <Resetting />
            </PublicAppBar>
          }
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Fetching>
  );
};

export const App = (): JSX.Element => {
  return (
    <>
      <CssBaseline />
      <AlertProvider.Provider>
        <LoadingProvider.Provider>
          <AppProvider.Provider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ja}
              dateFormats={{ monthAndYear: "yyyy年MM月" }}
            >
              <>
                <AlertList />
                <Loading />
                <BrowserRouter>
                  <Routes>
                    <Route path="/message" element={<Message />} />
                    <Route path="*" element={<AppRoute />} />
                  </Routes>
                </BrowserRouter>
              </>
            </LocalizationProvider>
          </AppProvider.Provider>
        </LoadingProvider.Provider>
      </AlertProvider.Provider>
    </>
  );
};
