import { FileEntity, webApi, download, Column, StaffEntity, generateUuid, setCookie, pathCombaine } from "lib-saitama";

/** 法人区分 */
export const corporateCategory = {
  unselected: 0,
  corporation: 1,
  soleProprietor: 2,
} as const;

/** パスワードの最小長 */
export const minPasswordLength = 8;

/** パスワードの最大長 */
export const maxPasswordLength = 20;

/** 使用可能な文字 */
export const halfWidthSymbols = "!\"#$%&'()*+,-_./:;<=>?@[\\]^_`{|}~";

/** パスワードに使用可能な文字列 */
export const passwordRegex = new RegExp(/[^0-9a-zA-Z !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+/);

/** メールアドレスの形式チェック */
export const emailFormatRegex = new RegExp(/^.*@.*\..*$/);

/** メールアドレスに使用可能な文字列 */
export const emailStringRegex = new RegExp(/[^0-9a-zA-Z\-+.@_]+/);

/** 数字のみ */
export const onlyNumberRegex = new RegExp(/[^0-9]+/);

/** 数字ハイフンのみ */
export const numberAndHyphenRegex = new RegExp(/[^\-0-9]+/);

/** 定数 */
export const Constants = {
  /** タイトル */
  title: "埼玉県財務受付ポータル",
};

/** Cookieのプロパティ名 */
export const CookiePropertyName = {
  /** gBizステータス */
  state: "state",
};

/** タイトルのシャドウ色 */
export const titleBackground = "linear-gradient(to right, #FF8F00, #FFF)";

/** 担当者選択画面の列 */
export const staffColumns: Array<Column<StaffEntity>> = [
  {
    width: 150,
    header: "担当者名",
    cell: "name",
  },
  {
    width: 150,
    header: "所属名",
    cell: "department",
  },
  {
    width: 150,
    header: "役職名",
    cell: "post",
  },
];

export const handleOnClickDownload = (item: FileEntity) => {
  return async () => {
    const response = await webApi.fetch("files/download", { url: item.recordId, outputFormat: "blob" });
    download(response, item.name);
  };
};

/**
 * gBizのログイン画面を呼び出す。
 * @param host 遷移先
 * @param redirectUrl リダイレクトURL
 */
export const gBizLogin = (host: string, redirectUrl: string) => {
  const state = generateUuid();
  setCookie(CookiePropertyName.state, state);
  const query = new URLSearchParams({
    response_type: "code",
    client_id: process.env.REACT_APP_CLIENT_ID ?? "",
    redirect_uri: redirectUrl,
    prompt: "login",
    state: state,
  }).toString();
  const url = pathCombaine(host, "oauth/authorize");
  window.location.href = url + "?" + query;
};
