/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Grid from "@mui/material/Grid";
import {
  Area,
  CaseEntity,
  CaseSummaryModel,
  Fetching,
  LoadingProps,
  Master,
  SelectDialog,
  SelectField,
  StaffEntity,
  TextField,
  convertToFiscalYear,
  genericStyles,
  serverErrorCodes,
  useAlertEx,
  useNarrowDown,
  webApi,
} from "lib-saitama";
import { ExternalAccount } from "../../models/ExternalAccount";
import { staffColumns } from "../../utilities/AppUtility";
import { MutableRefObject, useEffect, useState } from "react";

const styles = {
  loading: css({
    width: "100%",
    height: 200,
  }),
};

interface Props {
  master: Master;

  account?: ExternalAccount;

  caseSummaryModel: MutableRefObject<CaseSummaryModel>;

  caseEntity: CaseEntity;

  loading: LoadingProps;
}

export const BaseInfomation = (props: Props): JSX.Element => {
  const [data, setData] = useState<CaseEntity>(props.caseEntity);

  useEffect(() => setData(props.caseEntity), [props.caseEntity]);

  const alert = useAlertEx(props.master.messages);

  const handleOnChangeMainStaff = async (selected?: StaffEntity) => {
    data.mainStaffRecordId = selected?.recordId;
    data.mainStaffName = selected?.name;
    const response = await webApi.post("cases/staffs/main", data);
    if (response?.code === serverErrorCodes.ThePersonInChargeIsDuplicated) {
      alert(73);
      return;
    }
    const caseEntity: CaseEntity = response;
    props.caseSummaryModel.current.mainStaffName = caseEntity.mainStaffName;
    setData(caseEntity);
  };

  const handleOnChangeSubStaff = async (selected?: StaffEntity) => {
    data.subStaffRecordId = selected?.recordId;
    data.subStaffName = selected?.name;
    const response = await webApi.post("cases/staffs/sub", data);
    if (response?.code === serverErrorCodes.ThePersonInChargeIsDuplicated) {
      alert(73);
      return;
    }
    const caseEntity: CaseEntity = response;
    props.caseSummaryModel.current.subStaffName = caseEntity.subStaffName;
    setData(caseEntity);
  };

  return (
    <Area title="基本情報" contentCss={genericStyles.padding}>
      <Fetching css={styles.loading} {...props.loading}>
        <>
          <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
            <Grid item xs={6}>
              <TextField label="案件ID" css={genericStyles.w100p} value={data.id} readOnly={true} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="年度"
                css={genericStyles.w100p}
                value={convertToFiscalYear(data.periodFrom, 0, props.master.eraNames)}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label="案件名" css={genericStyles.w100p} value={data.name} readOnly={true} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="埼玉県担当者所属名" css={genericStyles.w100p} value={data.fullName} readOnly={true} />
            </Grid>
            <Grid item xs={6}>
              <TextField label="埼玉県担当者名" css={genericStyles.w100p} value={data.nameKanji} readOnly={true} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="事業者名" css={genericStyles.w100p} value={data.companyName} readOnly={true} />
            </Grid>
            <Grid item xs={6}>
              <SelectField
                label="担当者名1"
                css={genericStyles.w100p}
                onChange={handleOnChangeMainStaff}
                onClear={async () => await handleOnChangeMainStaff()}
                value={data.mainStaffName}
              >
                <SelectDialog
                  title="担当者"
                  columns={staffColumns}
                  onNarrowDown={useNarrowDown(true, "name")}
                  items={props.account?.staffEntities ?? []}
                />
              </SelectField>
            </Grid>
            <Grid item xs={6}>
              <SelectField
                label="担当者名2"
                css={genericStyles.w100p}
                onChange={handleOnChangeSubStaff}
                onClear={async () => await handleOnChangeSubStaff()}
                value={data.subStaffName}
              >
                <SelectDialog
                  title="担当者"
                  columns={staffColumns}
                  onNarrowDown={useNarrowDown(true, "name")}
                  items={props.account?.staffEntities ?? []}
                />
              </SelectField>
            </Grid>
          </Grid>
        </>
      </Fetching>
    </Area>
  );
};
