/** @jsxImportSource @emotion/react */
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
  Area,
  Column,
  DisplayMode,
  FileDialog,
  FileEntity,
  Master,
  SetState,
  Table,
  TaskEntity,
  appStyles,
  commentColumn,
  createIconColumn,
  defaultHeader,
  displayMode,
  emptyUuid,
  generateUuid,
  genericStyles,
  location,
  provider,
  removeArrayState,
  serverErrorCodes,
  updateArrayState,
  useAlertEx,
  usePromise,
  webApi,
} from "lib-saitama";
import { useCallback, useMemo, useState } from "react";
import Delete from "@mui/icons-material/Delete";
import { format } from "date-fns";

const styles = {
  tableHeight: 300,
};

interface Props {
  recordId: string;

  setSentFileEntities: SetState<FileEntity[]>;

  master: Master;
}

export const Submission = ({ recordId, setSentFileEntities, master }: Props): JSX.Element => {
  const alert = useAlertEx(master.messages);

  const [temporaryFileEntities, setTemporaryFileEntities] = useState<FileEntity[]>([]);

  const [file, setFile] = useState<{
    displayMode: DisplayMode;
    fileEntity: FileEntity;
    commit: boolean;
    taskEntity?: TaskEntity;
    onClose: (result?: FileEntity) => void;
  }>();

  const handleOnClickAdd = () => {
    setFile({
      commit: false,
      displayMode: displayMode.Register,
      fileEntity: {
        recordId: generateUuid(),
        relatedRecordId: emptyUuid(),
        path: "",
        name: "",
        uploadedAt: new Date(),
        size: 0,
        location: location.Unassignment,
        provider: provider.Company,
        related: false,
        deleted: false,
        createdAt: new Date(),
        createdBy: "",
        updatedAt: new Date(),
        updatedBy: "",
      },
      onClose: (result?: FileEntity) => {
        if (result != null) {
          result.relatedRecordId = recordId;
          setTemporaryFileEntities((value) => {
            return [...value, result];
          });
        }
        setFile(undefined);
      },
    });
  };

  const handleOnClickLinkTemporary = useCallback((item: FileEntity) => {
    return () => {
      setFile({
        commit: false,
        displayMode: displayMode.Edit,
        fileEntity: item,
        onClose: (result?: FileEntity) => {
          if (result != null) {
            updateArrayState(
              result,
              setTemporaryFileEntities,
              (fileEntity: FileEntity) => fileEntity.recordId === result.recordId
            );
          }
          setFile(undefined);
        },
      });
    };
  }, []);

  const handleOnClickDeleteTemporary = useCallback((item: FileEntity) => {
    return () => {
      removeArrayState(setTemporaryFileEntities, (value) => value.recordId === item.recordId);
    };
  }, []);

  const handleOnClickSend = () => register.execute([...temporaryFileEntities]);

  const register = usePromise(
    useCallback(
      async (args: FileEntity[]) => {
        let isError = false;
        let isError42 = false;
        for (const fileEntity of args) {
          if (fileEntity.file == null) {
            return;
          }

          try {
            const formData = new FormData();
            formData.append("RecordId", fileEntity.recordId);
            formData.append("RelatedRecordId", fileEntity.relatedRecordId);
            formData.append("Name", fileEntity.name);
            formData.append("Size", fileEntity.size.toString());
            formData.append("Comment", encodeURI(fileEntity.comment ?? ""));
            formData.append("Location", fileEntity.location.toString());
            formData.append("Provider", fileEntity.provider.toString());
            if (fileEntity.receiptedAt != null) {
              formData.append("ReceiptedAt", format(fileEntity.receiptedAt, "yyyy/MM/dd"));
            }
            formData.append("Related", fileEntity.related ? "true" : "false");
            formData.append("File", fileEntity.file);
            const response = await webApi.post("files", formData, { inputFormat: "formData" });
            if (
              response?.code === serverErrorCodes.FileWasUploadedThatExceededTheLimitForEachBusiness ||
              response?.code === serverErrorCodes.FileWasUploadedThatExceededTheLimitForEachBusinessAndProject
            ) {
              isError42 = true;
              throw "";
            }
            removeArrayState(setTemporaryFileEntities, (value: FileEntity) => value.recordId === response.recordId);
            setSentFileEntities((value) => [response, ...value]);
          } catch {
            isError = true;
          }
        }

        // アップロード時に総容量を超えていた場合
        if (isError42) {
          alert(42);
        }

        // アップロードの失敗が1件でもあった場合
        if (isError) {
          alert(57);
        }
      },
      [setSentFileEntities, alert]
    )
  );

  const temporaryColumns: Array<Column<FileEntity>> = useMemo(() => {
    return [
      createIconColumn(<Delete />, handleOnClickDeleteTemporary, "削除"),
      {
        width: 300,
        header: "ファイル名",
        cell: "name",
      },
      commentColumn(handleOnClickLinkTemporary, 350),
    ];
  }, [handleOnClickDeleteTemporary, handleOnClickLinkTemporary]);

  return (
    <>
      <Area
        title="ファイルの提出"
        remark="（＋ボタンから提出するファイルを選択し、提出ボタンを押して提出してください。）"
      >
        <Grid container rowSpacing={1}>
          <Grid item xs={12} container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Button
              css={[appStyles.middleButton, genericStyles.marginRight]}
              variant="contained"
              onClick={handleOnClickSend}
              disabled={temporaryFileEntities.length === 0}
            >
              提出
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Table
                virtual
                recordKey="recordId"
                height={styles.tableHeight}
                maxHeight={styles.tableHeight}
                items={temporaryFileEntities}
                columns={temporaryColumns}
                onClickAdd={handleOnClickAdd}
                stripe
                options={{ defaultHeader }}
              />
            </Paper>
          </Grid>
        </Grid>
        {file != null && (
          <FileDialog
            displayMode={file.displayMode}
            commit={file.commit}
            fileEntity={file.fileEntity}
            onClose={file.onClose}
            master={master}
          />
        )}
      </Area>
    </>
  );
};
